export var ChannelType;
(function (ChannelType) {
    ChannelType[ChannelType["Other"] = 0] = "Other";
    ChannelType[ChannelType["Direct"] = 1] = "Direct";
    ChannelType[ChannelType["Counter"] = 2] = "Counter";
    ChannelType[ChannelType["Cut"] = 3] = "Cut";
    ChannelType[ChannelType["Virtual"] = 4] = "Virtual";
})(ChannelType || (ChannelType = {}));
export var DataPrecision;
(function (DataPrecision) {
    DataPrecision[DataPrecision["Realtime"] = 0] = "Realtime";
    DataPrecision[DataPrecision["History"] = 1] = "History";
    DataPrecision[DataPrecision["Daily"] = 2] = "Daily";
    DataPrecision[DataPrecision["Monthly"] = 3] = "Monthly";
})(DataPrecision || (DataPrecision = {}));
