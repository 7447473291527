import { setAuthToken } from '@/auth';
import { delay } from '@/utils/delay';
import Vue from 'vue';
var axios = require('axios').default;
var State;
(function (State) {
    State[State["Loading"] = 0] = "Loading";
    State[State["Authenticating"] = 1] = "Authenticating";
    State[State["Success"] = 2] = "Success";
    State[State["Fail"] = 3] = "Fail";
})(State || (State = {}));
export default Vue.extend({
    data: function () {
        return {
            State: State,
            deviceInfo: {
                name: 'Attendi...',
                platform: '...',
                fullUUID: null
            },
            uuid: '',
            token: '',
            state: State.Loading,
            error: false
        };
    },
    mounted: function () {
        this.init();
    },
    watch: {
        '$route.query': function () {
            this.init();
        }
    },
    methods: {
        init: function () {
            var _this = this;
            var _a = this.$route.query, token = _a.token, uuid = _a.uuid;
            if (!token || !uuid) {
                console.warn('Token or UUID parameters (query) not set');
                return;
            }
            this.uuid = uuid;
            this.token = decodeURIComponent(token);
            // fetch device info
            axios.get("/api/devices/".concat(uuid))
                .then(function (r) {
                _this.deviceInfo.name = r.data.alias;
                _this.deviceInfo.platform = r.data.platformName;
                _this.deviceInfo.fullUUID = r.data.deviceID;
            })
                .then(function () {
                _this.state = State.Authenticating;
                _this.login();
            });
        },
        login: function () {
            var _this = this;
            var req = axios.post('/api/auth/guest', { uuid: this.uuid, token: this.token }).then(delay(2000));
            req.then(function (resp) {
                // authentication succeded
                var token = resp.data.token;
                if (token) {
                    _this.state = State.Success;
                    setAuthToken(token);
                    setTimeout(function () { return _this.$router.push("/device/".concat(_this.deviceInfo.fullUUID, "/workload")); }, 3000);
                }
                else
                    _this.state = State.Fail;
            });
            req.catch(function (error) {
                // any error
                _this.error = error;
                _this.state = State.Fail;
            });
        }
    }
});
