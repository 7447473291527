<template>
    <b-form-group :disabled="fetching">
        <b-form-checkbox switch  v-model="workstatus" @change="toggleWorkStatus()">
            <i class="fa fa-circle-notch fa-spin" v-if="fetching"></i> 
            <i class="fa fa-exclamation-triangle red" v-else-if="workstatus === false"></i>
            {{currentValueLabel}}
        </b-form-checkbox>
    </b-form-group>
</template>
<script>
const axios = require('axios').default;
export default {
    data(){
        return {
            fetching:true,
            workstatus: null
        }
    },
    mounted(){
        this.updateStatus();
    },
    computed:{
        currentValueLabel(){
            if (this.workstatus === true)
                return 'Letture da campo attive';
            else if (this.workstatus === false)
                return 'Letture da campo non attive';
            else 
                return 'Caricamento...';

        }
    },
    methods:{
        updateStatus(){
            this.fetching = true;
            axios.get("/api/data/workstatus").then(result => {
                this.workstatus = result.data.currentValue;
                this.fetching = false;
            })
        },
        toggleWorkStatus(){
            this.fetching = true;
            axios.post("/api/data/workstatus", this.workstatus ? 'true' : 'false', { headers: { 'Content-Type': 'application/json' }}).then(res => {
                if (res.statusCode == 200)
                    this.showToast('Workload status modificato', "Attivazione\\disattivazione completata", "success");
                this.fetching = false;
            });
        }
    }
}
</script>