import Vue from 'vue';
import App from './App.vue';
import { BootstrapVue } from 'bootstrap-vue';
import Grid from '@/common/components/Grid.vue';
import FormRow from '@/common/components/FormRow.vue';
import Signal from '@/common/components/Signal.vue';
import EciButton from '@/common/components/EciButton.vue';
import NetworkIcon from '@/common/components/NetworkIcon.vue';
import PageTitle from '@/common/components/PageTitle.vue';
import StatusIcon from '@/common/components/StatusIcon.vue';
import SignalIcon from '@/common/components/SignalIcon.vue';
import LoadingPlaceholder from '@/common/components/LoadingPlaceholder.vue';
import HorizontalScrollText from '@/common/components/HorizontalScrollText.vue';
import Vuelidate from 'vuelidate';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-select/dist/vue-select.css';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import vSelect from 'vue-select';
import { getAuthToken } from '@/auth';
import * as moment from 'moment';
Vue.config.productionTip = false;
// Plugin 
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.component('v-select', vSelect);
// Common shared components
Vue.component('grid', Grid);
Vue.component('form-row', FormRow);
Vue.component('signal', Signal);
Vue.component('eci-button', EciButton);
Vue.component('network-icon', NetworkIcon);
Vue.component('page-title', PageTitle);
Vue.component('status-icon', StatusIcon);
Vue.component('signal-icon', SignalIcon);
Vue.component('loading-placeholder', LoadingPlaceholder);
Vue.component('horizontal-scroll-text', HorizontalScrollText);
// must be imported after Vue.use(Vuex)!
import router from '@/router';
import store from '@/store';
// Filters
Vue.filter('date', function (value, format) {
    if (!format)
        format = "YYYY/MM/DD HH:mm:ss";
    if (!value)
        return null;
    var m;
    if (value instanceof Date)
        m = moment(value);
    else if (typeof (value) == "string")
        m = moment(value);
    else
        m = value;
    return m.format(format);
});
var numeral = require('numeral');
Vue.filter('number', function (value, format) {
    if (!format)
        format = '0,0.[0000]';
    else if (typeof (format) == 'number')
        format = "0,[" + new Array(format).join('0') + "]";
    return numeral(value).format(format);
});
Vue.filter('coalesce', function (value, ifNull) {
    return value == null || value == undefined ? ifNull : value;
});
import toastMixin from '@/mixins/toast';
import mqttMixin from '@/mixins/mqtt';
import sessionMixim from '@/mixins/session';
Vue.mixin(toastMixin);
Vue.mixin(mqttMixin);
Vue.mixin(sessionMixim);
// Setup HTTP Interceptor
axios.interceptors.request.use(function (config) {
    var token = getAuthToken();
    if (token)
        config.headers.Authorization = "Bearer " + token;
    config.headers["X-Device"] = store.state.device;
    return config;
}, function (err) {
    return Promise.reject(err);
});
new Vue({
    router: router,
    store: store,
    render: function (h) { return h(App); }
}).$mount('#app');
