<template>
    <div>
        <table class="table" v-show="state.scanned">
            <thead>
                <tr>
                    <th>SSID</th>
                    <th class="text-center">Segnale</th>
                    <th style="width:1px">&nbsp;</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(ssid,i) of ssids" :key="i">
                    <td><i class="fa fa-wifi mr-2"></i> {{ssid.name}}</td>
                    <td class="text-center"><signal :value="ssid.signal"></signal></td>
                    <td class="text-right"><button class="btn btn-primary btn-sm" @click="connect(ssid.name)">Connetti</button></td>
                </tr>
            </tbody>
        </table>
        <p v-show="!state.scanned" class="text-center text-muted fs-80 my-5">
            Premi il pulsante in basso per effettuare una scansione delle reti WiFi
        </p>
        <hr>
        <!-- FOOTER -->
        <div class="row align-items-center">
            <div class="col-auto">
                <b-button @click="$emit('complete')">Close</b-button>
            </div>
            <div class="col">
                <b-progress :value="state.scanPerc" :max="100"></b-progress>
            </div>
            <div class="col-auto">
                <eci-button icon="fa-wifi" @click="scan()" :disabled="state.scanning" :loading="state.scanning">Scansiona reti</eci-button>
            </div>
        </div>
        
    </div>
</template>
<script>
import Vue from 'vue';
const axios = require('axios').default;
export default Vue.extend({
    data(){
        return {
            ssids:[],
            state:{
                scanning: false,
                scanned: false,
                scanPerc: 0
            }
        }
    },
    methods:{
        scan(){
            const scanTime = 5;
            let interval = 0;
            
            // starts the scan
            this.state.scanning = true;
            axios.get('/api/network/wifi/scan').then(res => {
                this.ssids = res.data;
                this.state.scanning = false;
                this.state.scanned = true;
                // stops the progress bar
                clearInterval(interval);
                this.state.scanPerc = 100;
            });

            // start progress bar animation
            let scanProgress = 0;
            let self = this;
            // updates progress 
            interval = setInterval(function(){
                scanProgress += 0.5;
                self.state.scanPerc = (scanProgress / scanTime) * 100;
            }, 500);
        },
        connect(ssid){
            this.$emit('complete', { reason:'connect', data: { ssid: ssid }})
        }
    }
});
</script>