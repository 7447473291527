<template>
    <div class="row align-items-center">
        <div class="col">
            <form-row label="Connessione primaria (WAN)" description="Questa connessione verrà utilizzata come collegamento primario verso Internet">
                <b-form-select :options="values" v-model="selected"></b-form-select>
            </form-row>
        </div>
        <div class="col-auto">
            <eci-button icon="fa fa-save" @click="save()" :loading="saving">Salva</eci-button>
        </div>
    </div>
</template>
<script>
const axios = require('axios').default;
import { IFaceType } from '@/models/Network';
export default {
    props:{
        connections:{
            type: Array
        }
    },
    watch:{
        connections:{
            immediate: true,
            handler(conns){
                if (conns)
                    this.setOptions(conns);
            }
        }
    },
    data(){
        return {
            values: [],
            selected:'',
            saving: false
        }
    },
    mounted(){
        if (!this.connections)
            axios.get('/api/network/connections').then(result => this.setOptions(result.data));

        axios.get('/api/network/primary').then(result => this.selected = result.data.name);
    },
    methods:{
        setOptions(connections){
            this.values = connections.map(con => ({text:con.name+" ["+IFaceType.ToString(con.type)+"]", value:con.name}));
        },
        save(){
            this.saving = true;
            axios.post("/api/network/"+this.selected+"/primary").then(result => {
                if (result.status == 200)
                    this.onSaved();
                this.saving = false;
            });
        },
        onSaved(){
            this.$bvToast.toast('Impostazione salvata', { title: 'Salvataggio', variant:'success'});
        }
    }
    
}
</script>