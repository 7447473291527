<template>
    <grid :loading="state.fetching">
        <template v-slot:title>
            <i class="fas fa-chart-pie"></i> Riepilogo del carico di lavoro
        </template>
        <doughtnut-chart style="height:150px;" :chartData="chartData" :options="chartOptions" v-if="state.ready" ></doughtnut-chart>
        <loading-placeholder v-else/>
        <p class="text-muted fs-80 mb-0 mt-3">
            <i class="fas fa-sync"></i> Ultima sincronizzazione configurazione: {{sync.lastSync | coalesce('--/--/---- --:--:--') | date('YYYY/MM/DD HH:mm:ss')}} <br/>
            <i class="fas fa-hashtag"></i> Dispositivi gestiti: {{sync.devicesCount | coalesce('-')}}, canali gestiti: {{sync.channelsCount | coalesce('-')}}
        </p>
        

        <template v-slot:buttons>
            <b-dropdown-item @click="cloudSync()">
                Forza sincronizzazione
            </b-dropdown-item>
        </template>
    </grid>
</template>
<script>
import DoughtnutChart from '@/components/charts/DoughtnutChart.vue';
const axios = require('axios').default;

export default {
    components:{
        DoughtnutChart
    },
    data(){
        return {
            state:{
                ready:false,
                fetching: false
            },
            sync:{
                lastSync:null,
                channelsCount:null,
                devicesCount:null
            },
            summary:{
                okChannels:0,
                protocolErrorChannels:0,
                pluginNotFoundChannels:0,
                timeoutChannels:0,
                genericErrorChannels: 0
            },
            chartOptions:{
                // chart options
                responsive: true, 
                maintainAspectRatio: false,
                legend:{
                    position: 'right'
                }
            }
        }
    },
    mounted(){
        this.refresh();
    },
    methods:{
        refresh(){
            this.state.fetching = true;
            axios.get('/api/data/realtime/summary').then(result => {
                Object.assign(this.summary, result.data);
                this.state.ready = true;
                this.state.fetching = false;
            });
            axios.get('/api/configuration/sync').then(result => {
                Object.assign(this.sync, result.data);
            });
        },
        cloudSync(){
            axios.post('/api/cloud/sync').then(result => {
                if (result.data.syncResult === true)
                    this.showToast('Completato', 'Sincronizzazione del carico di lavoro completata', 'success');
                else if (result.data.syncResult === false)
                    this.onCloudSyncError();     
            }).catch(e => this.onCloudSyncError());
        },
        onCloudSyncError(){
            this.showToast('Errore', 'Impossibile sincronizzare il carico di lavoro', 'danger');
        }
    },
    computed: {
        chartData()
        { 
            return {
                // chart data
                labels: ['Acquisiti correttamente','Errore di protocollo','Plugin mancante', 'Timeout di comunicazione', 'Errore generico'],
                datasets:[{
                    label:'Dataset',
                    data:[this.summary.okChannels,this.summary.protocolErrorChannels,this.summary.pluginNotFoundChannels, this.summary.timeoutChannels, this.summary.genericErrorChannels],
                    backgroundColor:['#43a047','#fb8c00','#757575', '#f4511e', '#e53935']
                }]
            }
        }
    }
    
}
</script>