import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
var axios = require('axios').default;
var store = new Vuex.Store({
    state: {
        device: '',
        deviceInfo: {
            runningApplication: '',
            deviceType: '',
            deviceName: ''
        },
        identity: {
            platformName: null,
            alias: null
        },
        workloadChangeDate: null
    },
    mutations: {
        setDevice: function (state, device) {
            if (state.device != device) {
                state.device = device;
                axios.get('/api/devices/whoami').then(function (res) {
                    if (res.status == 200) {
                        Object.assign(state.deviceInfo, res.data.deviceInfo);
                        Object.assign(state.identity, res.data.identity);
                    }
                });
            }
        },
        setWorkloadChangeDate: function (state, value) {
            state.workloadChangeDate = value;
        }
    },
    actions: {
        newWorkload: function (context) {
            context.commit('setWorkloadChangeDate', new Date());
        }
    }
});
export default store;
