import { PermissionLevel } from '@/models/user-session';
import Vue from 'vue';
import { getSessionInfo } from '@/auth';
export default Vue.extend({
    methods: {
        getCurrentUser: function () {
            return getSessionInfo();
        }
    },
    computed: {
        isGuest: function () {
            var _a;
            return ((_a = this.getCurrentUser()) === null || _a === void 0 ? void 0 : _a.permission) == PermissionLevel.Guest;
        }
    }
});
