export const EciStatusCode = {
    Ok: 0,
    ProtocolError: 0xFC,
    PluginNotFound: 0xFD,
    Timeout:  0xFE,
    GenericError: 0xFF,

    ToString(val){
        switch(val){
            case EciStatusCode.Ok:
                return "Ok";
            case EciStatusCode.ProtocolError:
                return "Errore protocollo";
            case EciStatusCode.PluginNotFound:
                return "Plugin non trovato"
            case EciStatusCode.Timeout:
                return "Timeout";
            default:
            case EciStatusCode.GenericError:
                return "Errore generico";

        }
    }
}