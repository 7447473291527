import { Line, mixins } from 'vue-chartjs';
import Vue from 'vue';
export default Vue.extend({
    extends: Line,
    mixins: [mixins.reactiveProp],
    props: {
        chartData: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: true
        }
    },
    mounted: function () {
        this.updateChart();
    },
    methods: {
        updateChart: function () {
            this.renderChart(this.chartData, this.options);
        }
    }
});
