<template>
    <b-row>
        <b-col class="pr-1">
            <b-form-datepicker
            locale="it-IT"
            placeholder="-"
            v-model="dateStr"
            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
            today-button
            reset-button
            close-button></b-form-datepicker>
        </b-col>
        <b-col class="pl-1">
            <b-form-input
            type="time"
            step="1"
            :state="dateStr && !timeStr ? false : null"
            v-model="timeStr"
            :disabled="!dateStr" />
        </b-col>
    </b-row>
    
</template>
<script>
    import moment from "moment"

    export default {
        name: "EciDateTimePicker",
        props: {
            value: { type: Date }
        },
        data() {
            return {
                dateStr: null,
                timeStr: null
            }
        },
        computed: {
            datetime() {
                if (!this.dateStr || !this.timeStr)
                    return null;
                return moment(this.dateStr+"T"+this.timeStr).toDate();
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    this.dateStr = value != null ? moment(value).format("YYYY-MM-DD") : null;
                    this.timeStr = value != null ? moment(value).format("HH:mm:ss") : null;
                }
            },
            dateStr: {
                handler(value) {
                    if (!value)
                    {
                        this.timeStr = null;
                    }
                    else
                    {
                        if (!this.timeStr)
                            this.timeStr = "00:00:00";
                    }

                    this.$emit("update", this.datetime);
                }
            },
            timeStr: {
                handler(value) {
                    this.$emit("update", this.datetime);
                }
            }
        }
    }
</script>
<style scoped>
    b-form-datepicker {
        white-space: nowrap!important;
    }
    input[type=time] {
        border-radius: 0.25rem;
        min-height: calc(1.5em + 0.75rem + 4px);
    }
</style>