<template>
    <grid>
        <template v-slot:title>
            Riepilogo Eventi di sistema
        </template>

        <div>
            <div class="row events-summary">
                <div class="col-3">
                    <!-- INFORMATION -->
                    <i class="fa fa-info-circle info"></i>
                    <p class="cnt">{{events.informationEvents}}</p>
                    <span class="desc">Eventi</span>
                </div>
                <div class="col-3">
                    <!-- USER ERRORS  -->
                    <i class="fa fa-bell"></i>
                    <p class="cnt">{{events.userErrorEvents}}</p>
                    <span class="desc">Errori Strumentali</span>
                </div>
                <div class="col-3">
                    <!-- SYSTEM ERRORS -->
                    <i class="fa fa-exclamation-circle warning"></i>
                    <p class="cnt">{{events.systemErrorEvents}}</p>
                    <span class="desc">Errori di sistema</span>
                </div>
                <div class="col-3">
                    <!-- CRITICAL EVENTS -->
                    <i class="fa fa-exclamation-triangle red"></i>
                    <p class="cnt">{{events.criticalEvents}}</p>
                    <span class="desc">Errori critici</span>
                </div>
            </div>
        </div>

    </grid>
</template>
<script>
const axios = require('axios').default;
export default {
    data(){
        return {
            events: {
                informationEvents:0,
                userErrorEvents:0,
                systemErrorEvents:0,
                criticalEvents:0
            },
            state:{
                loading: false
            }
        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            this.state.loading = true;
            axios.get('/api/events/summary').then(result => {
                Object.assign(this.events, result.data);
                this.state.loading = false;
            });
        }
    }

}
</script>
<style lang="scss" scoped>
.events-summary {
    & > div {
        text-align:center;

        &:not(:last-child){
            border-right:1px solid #eee;
        }

        .fa {
            font-size:4rem;
            display:block;
            margin-bottom:.5rem;
        }

        // contatore 
        .cnt {
            margin-bottom:.25rem;
            font-weight:bold;
            font-size:1.5rem;
        }

        .desc {
            margin-top:0;
            font-size:.9rem;
        }
    }
}
</style>