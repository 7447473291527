<template>
    <b-form>
        <form-row label="Service">
            <b-form-select v-model="$v.config.service.$model" :options="services" text-field="name" value-field="service"></b-form-select>
        </form-row>
        <form-row label="Username">
            <b-form-input type="text" v-model="config.username" placeholder="Username di autenticazione"></b-form-input>
        </form-row>
        <form-row label="Password">
            <b-form-input type="text" v-model="config.password" placeholder="Password di autenticazione"></b-form-input>
        </form-row>
        <form-row label="Hostname">
            <b-form-input type="text" v-model="$v.config.hostname.$model" :state="!$v.config.hostname.$error" placeholder="Nome DNS da associare"></b-form-input>
        </form-row>
        <div class="buttons text-right">
            <eci-button icon="fa-save" :disabled="$v.$invalid || state.loading" :loading="state.loading" @click="save()">Salva</eci-button>
        </div>
    </b-form>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
const axios = require('axios').default;
export default {
    name:'DDNSSettings',
    data(){
        return {
            services:[],
            config: {
                username:null,
                password:null,
                hostname:null,
                service:null
            },
            state:{
                loading: false
            }
        }
    },
    validations:{
        config: {
            hostname: { required },
            service: { required }
        }
    },
    mounted(){
      this.loadServices();  
      this.loadConfig();
    },
    methods:{
        loadServices(){
            axios.get('/api/ddns/services').then(res => this.services = res.data);
        },
        loadConfig(){
            axios.get('/api/ddns/config').then(res => this.setValue(res.data));
        },
        setValue(v){
            Object.assign(this.config, v);
        },
        save(){
            this.state.loading = true;
            axios.post('/api/ddns/config', this.config).then(res => {
                if (res.data == true)
                    this.$bvToast.toast('Impostazioni salvate', { title:'Salvataggio riuscito'})
                this.state.loading = false;
            }).catch(error => {
                
            });
        }
    }
}
</script>