import Vue from 'vue';
export default Vue.extend({
    props: {
        text: {
            required: true,
            type: String
        }
    },
    data: function () {
        return {
            viewportWidth: 0,
            textWidth: 0,
            position: 'left',
            timer: 0,
            abort: new AbortController()
        };
    },
    watch: {
        'text': function () {
            this.updateWidth();
        }
    },
    mounted: function () {
        var _this = this;
        this.updateWidth();
        // listen for changes in the element size (can happen only on resize of the page)
        window.addEventListener('resize', function () { return _this.updateWidth(); }, { signal: this.abort.signal });
        this.timer = setInterval(function () { return _this.toggle(); }, 10000);
    },
    destroyed: function () {
        // clears the timer
        if (this.timer)
            clearInterval(this.timer);
        this.abort.abort();
    },
    computed: {
        targetX: function () {
            if (this.position == 'left' || !this.isOverflowing)
                return 0;
            else
                return -(this.textWidth - this.viewportWidth + 5);
        },
        isOverflowing: function () {
            return this.textWidth > this.viewportWidth;
        }
    },
    methods: {
        updateWidth: function () {
            // updates the known sizes of relevant DOM nodes
            var viewport = this.$refs['viewport'];
            this.viewportWidth = viewport.clientWidth;
            var text = this.$refs['text'];
            this.textWidth = text.scrollWidth;
        },
        toggle: function () {
            // invert the position
            this.position = this.position == 'left' ? 'right' : 'left';
        }
    }
});
