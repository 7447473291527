<template>
    <div class="p-2" :class="connected ? 'connected' : 'disconnected'">
        <h5>
            <network-icon :type="connection.iFaceType" class="mr-1"></network-icon>  {{connection.name}}
            <span class="float-right">
                <i class="fa fa-link" title="Connesso" v-if="connected" ></i>
                <i class="fa fa-unlink" title="Disconesso" v-else></i>
            </span>
        </h5>
        <table class="w-100">
            <thead>
                <tr>
                    <th style="width:50%"></th>
                    <th style="width:50%"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Stato</td>
                    <td>{{statusLabel}}</td>
                </tr>
                <tr>
                    <td>MAC Address</td>
                    <td>{{connection.physicalAddress}}</td>
                </tr>
                <tr>
                    <td>Indirizzo IP</td>
                    <td>{{connection.ip}}</td>
                </tr>
                <tr>
                    <td>Gateway</td>
                    <td>{{connection.gateway}}</td>
                </tr>
                <tr>
                    <td>DNS</td>
                    <td>{{connection.nameServers}}</td>
                </tr>

                <!-- ETH SPECIFIC STATUS -->
                <template v-if="connection.iFaceType == IFaceType.Eth">
                    <tr>
                        <td>Porta</td>
                        <td>{{connection.link ? 'Collegata' : 'Scollegata'}}</td>
                    </tr>
                </template>
                <!-- WIFI SPECIFIC STATUS -->
                <template v-if="connection.iFaceType == IFaceType.WiFi && connected">
                    <tr>
                        <td>Segnale</td>
                        <td>
                            <signal-icon :value="connection.signalStrength"></signal-icon>
                        </td>
                    </tr>
                </template>

                <!-- GSM STATUS -->
                <template v-if="connection.iFaceType == IFaceType.GSM && connected">
                    <tr>
                        <td>Segnale</td>
                        <td>
                            <signal-icon :value="connection.signalStrength"></signal-icon>
                        </td>
                    </tr>
                    <tr>
                        <td>Numero</td>
                        <td>{{connection.simNumber}}</td>
                    </tr>
                     <tr>
                        <td>SIM</td>
                        <td>
                            <span v-if="connection.simLocked"><i class="fa fa-lock"></i> Bloccata (PIN)</span>
                            <span v-else><i class="fa fa-unlock"></i> Sbloccata</span>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>
</template>
<script>
import { IFaceStatus, IFaceType } from '@/models/Network';
export default {
    props:{
        connection: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
            IFaceType: IFaceType
        }
    },
    computed: {
        statusLabel(){
            switch(this.connection.status){
                case IFaceStatus.Disconnected:
                    return 'Disconnesso';
                case IFaceStatus.Connecting:
                    return 'In connessione...';
                case IFaceStatus.NeedAuth:
                    return 'Autenticazione richiesta';
                case IFaceStatus.Connected:
                    return 'Connesso';
                case IFaceStatus.Unknown:
                    return "<Unknown>";

            }
        },
        connected(){
            return this.connection.status == IFaceStatus.Connected;
        }
    }
}
</script>
<style lang="scss" scoped>
table {
    font-size:.8rem;
}

.disconnected {
    table {
        opacity:.85;
    }
}
</style>