var axios = require('axios').default;
import store from '@/store';
import Vue from 'vue';
function sub(endpoint, callback) {
    axios.get(endpoint).then(function (res) {
        if (res.status == 200)
            callback(res.data);
        // sub again
        sub(endpoint, callback);
    }).catch(function (err) {
        console.warn('Error during long-polling request: ' + endpoint + ": ", err);
        // sub again after 2s of delay to avoid spam
        setTimeout(function () { return sub(endpoint, callback); }, 2000);
    });
}
export default Vue.extend({
    store: store,
    data: function () {
        return {
            mqtt: {
                subscriptions: []
            }
        };
    },
    methods: {
        mqttSub: function (endpoint, callback) {
            if (this.mqtt.subscriptions.indexOf(endpoint) >= 0)
                return;
            sub(endpoint, callback);
            this.mqtt.subscriptions.push(endpoint);
        }
    },
    created: function () {
        var self = this;
        this.$store.subscribeAction(function (action, state) {
            if (action.type == 'newWorkload')
                if (self.workloadChange && typeof (self.workloadChange) == 'function')
                    self.workloadChange();
        });
    }
});
