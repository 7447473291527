<template>
    <grid>
        <template v-slot:title>Posizione</template>
        <l-map style="width:100%;height:250px" :center="center" :zoom="map.zoom" :option="map.options" v-if="!fetching && locationAvailable">
            <l-tile-layer :url="map.url" />
            <l-marker :lat-lng="center">
                <l-popup>
                    <p class="mb-0 bold text-center"><i class="fa fa-map-marker"></i> Posizione del dispositivo</p>
                    <p>Latitudine {{latitude | number(4)}}, Longitudine {{longitude | number(4)}}</p>
                </l-popup>
            </l-marker>
        </l-map>
        <div v-else-if="fetching">
            <p class="text-center"><i class="fa fa-circle-notch fa-spin"></i> Caricamento...</p>
        </div>
        <div v-else>
            <p class="text-center">Posizione non disponibile</p>
        </div>
        
    </grid>
</template>
<script>
import L, { latLng, Icon } from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'
const axios = require('axios').default;

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
export default {
    components:{
        LMap,
        LTileLayer,
        LMarker,
        LPopup
    },
    data(){
        return {
            fetching: false,
            latitude: 0,
            longitude: 0,
            map:{
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                zoom: 13,
                options:{
                    zoomSnap: 0.5
                }
            }
        }
    },
    computed:{
        center(){
            return latLng(this.latitude, this.longitude);
        },
        locationAvailable(){
            return this.latitude > 0 && this.longitude > 0;
        }
    },
    mounted(){
        this.fetching = true;
        axios.get('/api/host/gps/location').then(result => {
            this.latitude = result.data.latitude;
            this.longitude = result.data.longitude;
            this.fetching = false;
        }).catch(error => {
            this.fetching = false;
        });
    }
}
</script>