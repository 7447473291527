<template>
    <grid :loading="fetching">
        <template v-slot:title>
            <p class="text-center">Workload dispositivo</p>
        </template>
        <template v-if="ready">
            <p class="text-muted fs-80 text-center">Questo dispositivo gestisce il seguente carico di lavoro:</p>
            <div class="row">
                <div class="col-auto text-center">
                    <h5>Aggiornata il</h5>
                    <span class="cnt">{{lastSync.lastSync | coalesce('--/--/----') | date('YYYY/MM/DD HH:mm:ss')}}</span>
                </div>
                <div class="col text-center">
                    <h5>Strumenti</h5>
                    <span class="cnt">{{lastSync.devicesCount | coalesce('-')}}</span>
                </div>
                <div class="col text-center">
                    <h5>Canali</h5>
                    <span class="cnt">{{lastSync.channelsCount | coalesce('-')}}</span>
                </div>
            </div>
        </template>
        <template v-else>
            <h4 class="text-center"><i class="fa fa-circle-notch fa-spin"></i></h4>
        </template>
        
    </grid>
</template>
<script>
const axios = require('axios').default;
export default {
    data(){
        return {
            lastSync: {
                lastSync:null,
                channelsCount: null,
                devicesCount: null
            },
            fetching: false,
            ready:false
        }
    },
    mounted(){
        this.fetch();
    },
    computed:{
 
        
    },
    methods: {
        fetch(){
            this.fetching = true;
            axios.get('/api/configuration/sync').then(result => {
                this.lastSync.lastSync = new Date(result.data.lastSync);
                this.lastSync.channelsCount = result.data.channelsCount;
                this.lastSync.devicesCount = result.data.devicesCount;

                this.ready = true;
                this.fetching = false;
            });
        },
        refresh(){
            this.fetch();
        }
    }
}
</script>
<style scoped lang="scss">
h5 {
    font-size:.7rem;
}
.cnt {
    font-size:1.2rem;
}
</style>
