<template>
    <div class="page-title">
        <h1><slot/></h1>
        <p class="fs-80 text-muted mb-0">Device <span class="bold">#{{deviceInfo.deviceName}}</span> @ <span class="bold">{{deviceInfo.deviceType}}</span></p>
    </div>
</template>
<script>
export default {
    computed:{
        deviceInfo(){
            return this.$store.state.deviceInfo;
        },
        identity(){
            return this.$store.state.identity;
        }
    }
}
</script>
<style scoped lang="scss">
.page-title {
    text-align:center;
    margin-bottom:1rem;
    font-family: 'Montserrat';
    h1 {
        font-size:2rem;
    }
}
</style>