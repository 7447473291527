<template>
    <div id="main-layout" :class="{'sidebar-open':sidebarOpen}" :key="device">
        <div id="sidebar-container">
            <sidebar :open="sidebarOpen" :key="device"></sidebar>
        </div>
        <navbar @sidebar-toggle="toggleSidebar()" :key="device"></navbar>
        <div id="main-content">
            
            <ul class="bread-crumb"></ul>
            <div class="row justify-content-center">
                <div class="col-12 col-lg-9 col-xl-7"><router-view :key="device" /></div>
            </div>
        </div>
    </div>
</template>
<script>
const axios = require('axios').default;
import Navbar from '@/layout/Navbar.vue';
import Sidebar from '@/layout/Sidebar.vue';
export default {
    name: 'layout',
    components: {
        Navbar,
        Sidebar
    },
    data() {
        return {
            sidebarOpen: false
        }
    },
    mounted(){
        // subscribes and dispatch to Vuex-Store the global workload change event
        const self = this;
        this.mqttSub('/api/configuration/events/onworkloadchange', () => {
            self.$store.dispatch('newWorkload');
        });
    },
    
    methods:{
        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen;
        },
        workloadChange(){
            this.showToast('Carico di lavoro modificato','E\' stato modificato il carico di lavoro di questo dispositivo');
        },
    },
    computed: {
        sidebarWidth() {
            return this.sidebarOpen ? '250px' : '50px';
        },
        device(){
            return this.$store.state.device;
        }
    }
}
</script>
<style scoped>
    #main-layout {
        height:100%;
    }
    #main-content {
        height: 100%;
        padding: 1rem;
        
        padding-top: 70px;
        z-index: 2;
        position: absolute;
        background-color: #E5E9EC;
        overflow-y:scroll;
    }

    #main-layout >>> #navbar {
        transform:translateX(0);
    }
    #main-layout.sidebar-open #main-content,
    #main-layout.sidebar-open >>> #navbar {
        transform:translateX(250px);
    }
    #sidebar-container {
        height:100%;
        float:left;
        
    }
    #main-layout.sidebar-open #sidebar-container {
        width:250px;
    }

    @media(max-width:576px){
        /* MOBILE */

        #main-layout.sidebar-open #main-content,
        #main-layout.sidebar-open >>> #navbar {
            transition:transform ease-in-out .35s;
        }

        #main-content {
            margin-left:0;
            width:100%;
            
        }
        #sidebar-container {
            width:250px;
        }

    }
    @media(min-width:577px){
        /* DESKTOP */
        #sidebar-container {
            width: 50px;
        }
        #main-content {
            margin-left:50px;
            width:calc(100% - 50px);
        }
        #main-layout.sidebar-open #main-content {
            margin-left:0;
        }
    }
</style>