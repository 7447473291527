<template>
    <div id="events-viewer">
        <page-title><i class="fa fa-th-list"></i> Log ed eventi</page-title>
        <grid :loading="false">
            <!-- Filter -->
            <div class="mb-3">
                <h6>Seleziona un intervallo di date</h6>
                <b-row>
                    <b-col cols="12" lg="5">
                        <eci-date-time-picker :value="from" @update="updateFrom"></eci-date-time-picker>
                    </b-col>
                    <b-col class="text-center" align-self="center">
                        <i class="fa fa-arrow-down my-3 d-block d-lg-none"></i>
                        <i class="fa fa-arrow-right d-none d-lg-block"></i>
                    </b-col>
                    <b-col cols="12" lg="5">
                        <eci-date-time-picker :value="to" @update="updateTo"></eci-date-time-picker>
                    </b-col>
                </b-row>
                <h6 class="mt-4">Seleziona una tipologia di severity</h6>
                <b-row>
                    <b-col cols="12" md="6" class="mb-2">
                        <b-form-select v-model="severity">
                            <b-form-select-option :value="null">[Tutte le tipologie]</b-form-select-option>
                            <b-form-select-option v-for="(value, key) in severityOptions" :key="key" :value="value">
                                {{ key }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-row>
                            <b-col class="text-right">
                                <eci-button icon="fa-sync" class="mr-2" @click="refreshTable()">Aggiorna</eci-button>
                                <eci-button icon="fa-trash" variant="dark" @click="clearAskConfirm()">Elimina tutto</eci-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>

            <hr class="mt-1 mb-4" />

            <!-- Table -->
            <events-table id="events-table" :from="from" :to="to" :severity="severity" :perPage="15"></events-table>
        </grid>
    </div>
</template>
<script>
import moment from "moment"
import { EventSeverity } from "@/models/Events.js"
import Grid from "@/common/components/Grid.vue"
import EventsTable from "@/components/events/EventsTable.vue"
import EciDateTimePicker from "@/common/components/EciDateTimePicker.vue"
import EciButton from '@/common/components/EciButton.vue'

const axios = require('axios').default;
export default {
    name: 'Events',
    data() {
        return {
            from: moment().subtract(1, 'h').toDate(),
            to: null,
            severity: null,
            severityOptions: EventSeverity
        }
    },
    components: {
        EventsTable,
        EciDateTimePicker,
        EciButton,
        Grid
    },
    methods: {
        updateFrom(value) {
            this.from = value;
        },
        updateTo(value) {
            this.to = value;
        },
        refreshTable() {
            this.$root.$emit('bv::refresh::table', 'events-table');
        },
        clearAskConfirm() {
            if (!confirm("Confermi di volere rimuovere tutti i log presenti sul sistema?"))
                return;
            // clear logs and force table update 
            axios.post('/api/events/clear', { severity: null })
                .then(_ => this.refreshTable())
        }
    }
}
</script>
<style scoped>
    h6 {
        font-weight: bold;
    }
    eci-button {
        display: inline-block;
    }
</style>