<template>
    <grid>
        <template v-slot:title>Dynamic DNS</template>

         <div class="row align-items-center no-gutters mx-2">
            <div class="col-auto text-center pr-3">
                <i class="fa fa-globe-americas icon"></i>
            </div>
            <div class="col">
                <template v-if="!fetching">
                    <p>
                        <span class="bold">Ultimo aggiornamento</span>: {{status}}
                    </p>
                    <p>
                        <span class="bold">Data ultimo update</span>: {{state.lastUpdateTime | date('YYYY/MM/DD HH:mm:ss')}}
                    </p>
                    <p><span class="bold">Hostname</span>: {{state.updatedHostname}}</p>
                </template>
                <template v-else>
                    <p>
                        <i class="fa fa-circle-notch fa-spin"></i> Caricamento...
                    </p>
                </template>

            </div>
        </div>
    </grid>
</template>
<script>
const axios = require('axios').default;
import { DDNSUpdateResult } from '@/models/DDNS';
export default {
    data(){
        return {
            state: {
                lastUpdateTime: null,
                lastResult: null,
                updatedHostname: null
            },
            fetching: false
        }
    },
    computed:{
        status(){
            if (this.state.lastResult >= 0)
                return DDNSUpdateResult.ToString(this.state.lastResult);
            else 
                return "--";
        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            this.fetching = true;
            axios.get('/api/ddns/status').then(res => {
                if (res.data){
                    Object.assign(this.state, res.data);
                    this.state.lastUpdateTime = new Date(res.data.lastUpdateTime);
                }

                this.fetching = false;
            });
        },
        refresh(){
            this.fetch();
        }
    }
}
</script>
<style lang="scss" scoped>
.icon {
    color:#999;
    font-size:2.5rem;
}
p{
    font-size:.9rem;
    margin-bottom:0;
}
</style>