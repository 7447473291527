<template>
    <div>
        <page-title>
            Carico di lavoro
        </page-title>
        <p class="text-center">
            <eci-button icon="fas fa-redo" @click="updateValues()" :loading="state.loadingValues">Aggiorna</eci-button>
        </p>
        <div class="row mb-3">
            <div class="col-12 col-lg-6 mb-3 mb-lg-0">
               <realtime-summary></realtime-summary>
            </div>
            <div class="col-12 col-lg-6">
                <workload-stats class="h-100"></workload-stats>
            </div>
        </div>

        <div class="my-3 text-right" v-if="!isGuest">
            <work-status-toggler></work-status-toggler>
        </div>

        <!-- <grid bodyClass="pt-0"> -->
            <!-- <template v-slot:title>
                Carico di lavoro
            </template> -->
        <h5>Carico di lavoro: </h5>
            <loading-placeholder v-if="!state.ready"/>
            <div class="px-3" v-else>
                <workload-tree-node :node="node" v-for="node of nodes" :key="node.device.id" :values="values"></workload-tree-node>
                <p v-if="nodes.length == 0" class="text-center text-muted fs-80">Nessun carico di lavoro per questo dispositivo</p>
            </div>
        <!-- </grid> -->

       
    </div>
</template>
<script>
import WorkloadTreeNode from '@/components/workload/WorkloadTreeNode.vue';
import RealtimeSummary from '@/components/workload/RealtimeSummary.vue';
import CloudSync from '@/components/dashboard/CloudSync.vue';
import WorkStatusToggler from '@/components/workload/WorkStatusToggler.vue';
import WorkloadStats from '@/components/workload/WorkloadStats';

const axios = require('axios').default;
export default {
    components: { WorkloadTreeNode,  RealtimeSummary, CloudSync, WorkStatusToggler, WorkloadStats },
    data(){
        return {
            nodes:[],
            values:{}, // 👈 dictionary
            workStatus: null,
            state:{
                loadingValues: false,
                ready: false,
                interval: null
            }
        }
    },
    mounted(){
        this.updateWorkload();
        this.updateValues();
        // start auto-update cycle
        this.startAutoupdateCycke();
        
        
    },
    destroyed(){
        if (this.state.interval)
            clearInterval(this.state.interval);
    },
    methods:{
        // mixin event when a new workload is used
        workloadChange(){
            this.updateWorkload();
            this.updateValues();
        },
        startAutoupdateCycke(){
            const self = this;
            // starts cycle
            this.state.interval = setInterval(() => {
                if (!self.state.loadingValues)
                    self.updateValues();
            }, 5000);
        },
        updateWorkload(){
            this.state.ready = false;
            axios.get('/api/configuration/workload').then(result => {
                this.nodes = result.data;
                this.state.ready = true;
            });
        },
        updateValues(oncomplete){
            this.state.loadingValues = true;
            axios.get('/api/data/realtime/values', { params: { ids: null}}).then(result => {
                // crea un dizionario per un accesso pi� veloce
                let dict = {};
                result.data.forEach(item => {
                    dict[item.id] = item;
                })
                this.values = dict;

                // aggiorna UI
                this.state.loadingValues = false;

                // callback
                if (oncomplete)
                    oncomplete();
            });

            // updates children
            this.$children.forEach(child => {
                if (typeof(child.refresh) == 'function')
                    child.refresh();
            });           
        }
        
    }

}
</script>