<template>
    <div>
        <b-form v-if="!loading">
            <form-row label="Interfaccia attiva">
                <b-form-checkbox switch v-model="connection.enabled"></b-form-checkbox>
            </form-row>
            <form-row label="Modalità IP">
                <b-form-select :options="networkModes" class="form-control" v-model="connection.mode" :disabled="!connection.enabled"></b-form-select>
            </form-row>
            <template v-if="connection.mode == 1">
                <!-- STATIC IP SETTINGS -->
                <form-row label="Indirizzo IP Statico e Maschera">
                    <div class="row">
                        <div class="col-8">
                            <b-form-input v-model="$v.connection.ip.$model" placeholder="0.0.0.0" :disabled="!connection.enabled" :state="!$v.connection.ip.$error"></b-form-input>
                        </div>
                        <div class="col-4">
                            <b-form-input v-model="$v.connection.mask.$model" placeholder="0-32" :disabled="!connection.enabled" :state="!$v.connection.mask.$error"></b-form-input>
                        </div>
                    </div>
                    
                </form-row>
                <form-row label="Gateway">
                    <b-form-input v-model="$v.connection.gateway.$model" placeholder="0.0.0.0" :disabled="!connection.enabled" :state="!$v.connection.gateway.$error"></b-form-input>
                </form-row>
                <form-row label="DNS Primario">
                    <b-form-input v-model="$v.connection.dnsPrimary.$model" placeholder="0.0.0.0" :disabled="!connection.enabled" :state="!$v.connection.dnsPrimary.$error"></b-form-input>
                </form-row>
                <form-row label="DNS Secondario">
                    <b-form-input v-model="$v.connection.dnsSecondary.$model" placeholder="0.0.0.0" :disabled="!connection.enabled" :state="!$v.connection.dnsSecondary.$error"></b-form-input>
                </form-row>
            </template>
            <template v-if="connection.iFaceType == 1">
                <!-- WIFI CONNECTION -->
                <form-row label="SSID Rete WiFi">
                    <b-form-input placeholder="Nome della rete" v-model="$v.connection.ssid.$model" :state="!$v.connection.ssid.$error" :disabled="!connection.enabled"></b-form-input>
                </form-row>
                <form-row label="Tipo di autenticazione">
                    <b-form-select :options="authMethods" v-model="connection.authMethod"></b-form-select>
                </form-row>
                <form-row label="Password" v-if="connection.authMethod > 0">
                    <b-form-input placeholder="Password di accesso" v-model="$v.connection.passphrase.$model" type="password" :disabled="!connection.enabled"></b-form-input>
                </form-row>
            </template>

            <template v-if="connection.iFaceType == 2">
                <!-- GSM CONNECTION -->
                <form-row label="APN">
                    <b-form-input placeholder="Nome del punto di connessione" v-model="$v.connection.apn.$model" :disabled="!connection.enabled"></b-form-input>
                </form-row>
                <form-row label="SIM PIN">
                    <b-form-input placeholder="PIN della SIM" v-model="$v.connection.pin.$model" :disabled="!connection.enabled"></b-form-input>
                </form-row>
            </template>

            <div class="text-right buttons">
                <eci-button @click="openWifiScan()" icon="fa-wifi" class="float-left" v-if="connection.iFaceType == 1">Scan WiFi</eci-button>
                <eci-button @click="save(false)" :disabled="$v.$invalid || saving" :loading="saving" icon="fa-save">Salva</eci-button>
                <eci-button @click="save(true)" :disabled="$v.$invalid || saving" :loading="saving" icon="fa-check-square">Salva e applica</eci-button>
            </div>
        </b-form>
        <div v-else>
            <p class="text-center">Caricamento...</p>
        </div>
    </div>
</template>
<script>
const axios = require('axios').default;
import {required, ipAddress, between } from 'vuelidate/lib/validators';
import { openModal } from '@/common/components/EciModal';
import WiFiScanner from './WiFiScanner';
export default {
    props:['name'],
    data(){
        return {
            connection:{
                enabled: false,
                iFaceType: 0, // <-- readonly
                mode:0,
                ip: '',
                mask: 24,
                gateway: '',
                dnsPrimary: '',
                dnsSecondary: '',
                enabled: false,
                ssid: null,
                passphrase: null,
                authMethod: 0,
                pin: null,
                apn: null
            },
            networkModes:[{ value: 0, text:'DHCP'}, { value:1, text:'Static'}],
            authMethods:[{value:0, text:'None'}, {value: 1, text:'Dynamic WAP'}, {value:2, text:'WPA_PSK'}, { value:3, text: 'OWE'}, { value: 4, text: 'WPA_EAP'}, { value:5, text: 'WPA3'}],
            loading: true,
            saving: false
        }
    },
    validations(){
        let v = {
            
        };
        if (this.connection.mode == 1) {
            // STATIC 
            v = { 
                ...v,
                ip: {  required, ipAddress },
                mask: { required, between: between(0, 32) },
                gateway: { required, ipAddress },
                dnsPrimary: { required, ipAddress },
                dnsSecondary: { required, ipAddress }
            };
        }

        // WIFI connection validation
        if (this.connection.iFaceType == 1)
            v = {
                ...v,
                ssid: { required },
                passphrase: {}
            };

        // GSM Connection validation
        if (this.connection.iFaceType == 2)
            v = {
                ...v,
                apn: { required },
                pin: {}
            }

        return { connection: v};
    },
    methods:{
      setValue(props){
          Object.assign(this.connection, props);
          this.loading = false;
      },
      save(apply){
            if (this.$v.$invalid)
                return;
            let type = "eth";
            if (this.connection.iFaceType == 1)
                type = 'wifi';
            else if (this.connection.iFaceType == 2)
                type = 'gsm';
            
            this.saving = true;
            axios.post('/api/network/connection/'+this.name+'/config/'+type+'?applyNow='+apply, this.connection).then(res => this.onSaved());
      },
      onSaved(name){
        this.saving = false;
        this.showToast('Impostazioni salvate', 'Le impostazioni della connessione \''+this.name+'\' sono state salvate', 'success');
      },
      openWifiScan(){
        openModal('WiFi Scanner', WiFiScanner).then(result => {
            if (result.reason == 'connect'){
                // user asked to connect
                this.setValue({
                    enabled: true,
                    ssid: result.data.ssid,
                    passphrase:'',
                    authMethod: 2
                });
            }
        });
      }
    },
    mounted(){
        axios.get('/api/network/connection/'+this.name+'/config').then(result => this.setValue(result.data));
    }
}
</script>