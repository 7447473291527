export const DDNSUpdateResult = {
    Updated: 0,
    InvalidHostname: 1,
    InvalidAuth: 2,
    Abuse: 3,
    GenericError: 4,

    ToString(value){
        switch(value){
            case DDNSUpdateResult.Updated:
                return "Aggiornato";
            case DDNSUpdateResult.InvalidHostname:
                return "Hostname non valido";
            case DDNSUpdateResult.InvalidAuth:
                return "Autenticazione non valida";
            case DDNSUpdateResult.Abuse:
                return "Abuso";
            case DDNSUpdateResult.GenericError:
                return "Errore generico";
        }
    }
}