<template>
  <div>
      <page-title><i class="fa fa-desktop"></i> Dashboard</page-title>
      <div class="row">
          <div class="col-12 col-lg-4  mb-3 mb-lg-0">
                <cloud-check class="h-100"></cloud-check>
          </div>
          <div class="col-12 col-lg-8">
              <realtime-summary class="h-100"></realtime-summary>
          </div>
      </div>
      <div class="row mt-3">
          <div class="col-12 ">
              <connections-status></connections-status>
          </div>          
      </div>
      <div class="row mt-3 row-eq-height">
          <div class="col-12 col-lg-6">
              <application-state class="mb-3"></application-state>
              <ddns-status></ddns-status>
          </div>
          <div class="col-12 col-lg-6">
              <map-location></map-location>
          </div>
      </div>
  </div>
</template>

<script>
const axios = require('axios').default;
import CloudCheck from '@/components/dashboard/CloudCheck.vue';
import EventsSummary from '@/components/dashboard/EventsSummary.vue';
import ConnectionsStatus from '@/components/dashboard/ConnectionsStatus.vue';
import ApplicationState from '@/components/dashboard/ApplicationState.vue';
import DDNSStatus from '@/components/dashboard/DDNSStatus.vue';
import CloudSync from '@/components/dashboard/CloudSync.vue';
import MapLocation from '@/components/dashboard/MapLocation.vue';
import RealtimeSummary from '@/components/workload/RealtimeSummary.vue';

export default {
    components:{
        CloudCheck,
        EventsSummary,
        ConnectionsStatus,
        ApplicationState,
        'ddns-status':DDNSStatus,
        CloudSync,
        MapLocation,
        RealtimeSummary,
    },
    data(){
        return {
            live: true
        }
    },
    mounted() {
        this.live = true;
        this.refresh();
    },
    beforeDestroy(){
        this.live = false;
    },
    methods:{
        refresh(){
            const self = this;
            setTimeout(function(){
                self.$children.forEach($c => {
                    if ($c.refresh && typeof($c.refresh) == 'function')
                        $c.refresh();
                });
                if (self.live)
                    self.refresh();
            }, 30 * 1000);
        }
    }
}
</script>
