var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from 'vue';
var modalComp = Vue.extend({
    props: {
        title: {
            type: String,
            required: true
        },
        size: {
            type: String,
            default: 'sm'
        }
    },
    data: function () {
        return {
            active: false
        };
    },
    methods: {
        renderComponent: function (component, props) {
            var instance = new component({
                propsData: props
            });
            instance.$mount();
            this.$refs["modal-content"].appendChild(instance.$el);
            // 😢 Purtroppo toca usare any in questo caso...
            this.$refs["modal"].show('main-modal');
            this.active = true;
            // listen to component termination signal
            instance.$on('complete', this.componentComplete.bind(this));
        },
        componentComplete: function (result) {
            // forward the event
            this.$emit('complete', result);
        },
        onClosing: function (reason) {
            this.$emit('complete', { reason: reason, data: null });
            this.active = false;
        }
    }
});
export default modalComp;
function getDefaultOptions() {
    return { size: 'sm' };
}
/**
 * Apre una modal all'interno della pagina, utilizzando come contenuto un component specificato come parametro
 * @param title Il titolo della finestra
 * @param component Il component (Vue) da utilizzare come contenuto
 * @param componentProps Le props da passare al component quando viene inizializzato
 * @param modalOptions Configurazione di visualizzazione della modal
 */
export function openModal(title, component, componentProps, modalOptions) {
    return new Promise(function (resolve) {
        var finalOptions = __assign(__assign({}, getDefaultOptions()), modalOptions);
        var modal = new modalComp({
            propsData: __assign(__assign({}, finalOptions), { title: title })
        });
        modal.$on('complete', function (result) {
            console.log('Modal completed with result', result);
            modal.$destroy();
            document.getElementById('modal-target').innerHTML = "";
            resolve(result);
        });
        // Mount the component and renders it to the DOM
        modal.$mount();
        document.getElementById('modal-target').appendChild(modal.$el);
        modal.renderComponent(component, componentProps);
    });
}
