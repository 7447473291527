/**
 * Calculates the minimum value of an array
 * @param project Projection function, if not specified the array type will be used to perform the operation if it's numeric and return NaN otherwise
 */
function min(array, project) {
    if (array.length == 0)
        return NaN;
    if (!project)
        project = function (item) { return typeof item === 'number' ? item : NaN; };
    var m = Number.MAX_SAFE_INTEGER;
    for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
        var item = array_1[_i];
        var value = project(item);
        if (value < m)
            m = value;
    }
    return m;
}
/**
 * Calculates the maximum value of an array
 * @param project Projection function, if not specified the array type will be used to perform the operation if it's numeric and return NaN otherwise
 */
function max(array, project) {
    if (array.length == 0)
        return NaN;
    if (!project)
        project = function (item) { return typeof item === 'number' ? item : NaN; };
    var m = -Number.MAX_SAFE_INTEGER;
    for (var _i = 0, array_2 = array; _i < array_2.length; _i++) {
        var item = array_2[_i];
        var value = project(item);
        if (value > m)
            m = value;
    }
    return m;
}
/**
 * Calculates the average value of an array
 * @param project Projection function, if not specified the array type will be used to perform the operation if it's numeric and return NaN otherwise
 */
function avg(array, project) {
    if (array.length == 0)
        return NaN;
    if (!project)
        project = function (item) { return typeof item === 'number' ? item : NaN; };
    var sum = 0;
    for (var _i = 0, array_3 = array; _i < array_3.length; _i++) {
        var item = array_3[_i];
        sum += project(item);
    }
    // safe thanks to first input check
    return sum / array.length;
}
export { min, avg, max };
