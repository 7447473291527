var CookieName = 'Session-Token-JWT';
/**
 * Check whether the user is authenticated
 * @returns True if user is authenticated
 */
function isAuthenticated() {
    // gets the token from the storage
    var jwtToken = getAuthToken();
    if (!jwtToken)
        return false; // token not present - not authorized
    var tokenData = decodeJwt(jwtToken);
    if (!tokenData)
        return false;
    // expiration ("exp") is a unix timestamp in seconds
    var expirationDate = new Date(tokenData.exp * 1000);
    // compare expirationDate with now
    var now = new Date();
    return expirationDate > now;
}
/**
 * Retrieves the athentication token
 * @returns The current authentication token JWT (if any)
 */
function getAuthToken() {
    return getCookie(CookieName);
}
/**
 * Gets the current session's info
 * @returns The session info
 */
function getSessionInfo() {
    var token = getAuthToken();
    if (token)
        return decodeJwt(token);
    return null;
}
/**
 * Retrieves current session information encoded in a JWT session token
 * @param jwt The raw JWT token (encoded)
 * @returns The decoded content of the token
 */
function decodeJwt(jwt) {
    // a JWT token is just a 3 parts base64 strings
    var parts = jwt.split('.');
    if (parts.length < 3)
        return null;
    var json = JSON.parse(atob(parts[1]));
    return json;
}
/**
 * Overrides the current auth session token
 * @param token The session token received from the server
 */
function setAuthToken(token) {
    setCookie(CookieName, token, 10);
}
function getCookie(cookieName) {
    var cookies = document.cookie.split(';');
    for (var _i = 0, cookies_1 = cookies; _i < cookies_1.length; _i++) {
        var cookie = cookies_1[_i];
        var _a = cookie.split('=').map(function (i) { return i.trim(); }), name = _a[0], value = _a[1];
        if (name === cookieName)
            return value;
    }
}
function setCookie(name, value, maxAgeDays) {
    var maxAgeSeconds = maxAgeDays * 24 * 60 * 60;
    var cookieString = "".concat(name, "=").concat(value, "; max-age-seconds=").concat(maxAgeSeconds, "; path=/");
    document.cookie = cookieString;
}
;
function deleteCookie(cookieName) {
    document.cookie = "".concat(cookieName, "=; Max-Age=0; path=/");
}
export { isAuthenticated, decodeJwt, setAuthToken, getAuthToken, getSessionInfo };
