import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Layout from '@/layout/Layout.vue';
import ExtLayout from '@/layout/ExtLayout.vue';
import Login from '@/views/Login.vue';
import GuestLogin from '@/views/GuestLogin.vue';
import Guest from '@/views/Guest.vue';
import { getSessionInfo } from '@/auth';
import Dashboard from '@/views/Dashboard.vue';
import Events from '@/views/Events.vue';
import Settings from '@/views/Settings.vue';
import Workload from '@/views/Workload.vue';
import Plugins from '@/views/Plugins.vue';
import Operations from '@/views/Operations.vue';
import SMS from '@/views/SMS.vue';
import Unauthorized from '@/views/Unauthorized.vue';
import { PermissionLevel } from './models/user-session';
Vue.use(VueRouter);
var routes = [
    {
        // 🚪 LOGIN
        path: '/login',
        component: ExtLayout,
        meta: {
            title: 'Login'
        },
        children: [
            {
                path: '',
                meta: { title: 'Login' },
                component: Login
            }, {
                path: 'guest',
                meta: { title: 'Accesso Guest' },
                component: GuestLogin
            }
        ]
    },
    {
        // ❗ ERROR PAGES (open and public)
        path: '/device/:device/unauthorized',
        component: Layout,
        meta: { title: 'Non autorizzato' },
        children: [{ path: '', component: Unauthorized }]
    },
    {
        // 🔑 INTERNAL, REGULAR AREA
        path: '/device/:device',
        component: Layout,
        props: true,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: '',
                meta: { title: 'Dashboard' },
                name: 'Dashboard',
                component: Dashboard
            }, {
                path: 'events',
                name: 'Events',
                meta: { title: 'Eventi' },
                component: Events
            }, {
                path: 'settings',
                name: 'Settings',
                meta: { title: 'Impostazioni' },
                component: Settings
            }, {
                path: 'workload',
                name: 'workload',
                meta: { title: 'Carico di lavoro', allowGuest: true },
                component: Workload
            }, {
                path: 'plugins',
                name: 'plugins',
                meta: { title: 'Plugin' },
                component: Plugins
            }, {
                path: 'operations',
                name: 'operations',
                meta: { title: 'Operazioni & Strumenti' },
                component: Operations
            }, {
                path: 'sms',
                name: 'sms',
                meta: { title: 'SMS' },
                component: SMS
            }]
    }, {
        // 🔓 GUEST AREA
        path: '/device/:device/guest',
        component: Layout,
        props: true,
        meta: {
            requiresAuth: true,
            allowGuest: true
        },
        children: [
            {
                path: '',
                name: 'Guest Home',
                meta: { title: 'Riepilogo' },
                component: Guest
            }
        ]
    },
    {
        path: '*',
        redirect: function (to) {
            if (store.state.device)
                return '/device/' + store.state.device;
            return '/login';
        }
    }
];
var router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
});
router.beforeEach(function (to, from, next) {
    var _a;
    // updates the store based on the URL
    var device = to.params.device;
    store.commit('setDevice', device);
    // cambia il titolo
    var title = (((_a = to.meta) === null || _a === void 0 ? void 0 : _a.title) + (device ? ' - #' + device : "")) || '';
    Vue.nextTick(function () { return document.title = title; });
    if (to.matched.some(function (record) { return record.meta.requiresAuth; })) {
        // auth-protected route
        var user = getSessionInfo();
        if (user == null) {
            // 🛑 user not logged-in
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
        }
        else if (!to.matched.some(function (r) { return r.meta.allowGuest; }) && user.permission == PermissionLevel.Guest) {
            // 🛑 guest user navigating to a private area
            next({ path: "/device/".concat(device, "/unauthorized") });
        }
        else
            // ✅ authenticated and authorized
            next();
    }
    else
        // ✅ public area
        next();
});
export default router;
