<template>
    <div class="row align-items-center form-row">
        <div class="col-6">
            <label>{{label}}</label>
            <span class="description" v-show="hasDescription"><i class="fa fa-info-circle"></i> {{description}}</span>
        </div>
        <div class="col-6">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    props:['label', 'description'],
    computed:{
        hasDescription(){
            return this.description != null && this.description.trim().length > 0;
        }
    }
    
}
</script>
<style scoped>
.form-row:not(:last-child) {
    padding-bottom:.5rem;
    margin-bottom:.5rem;
    border-bottom:1px solid rgba(0,0,0,0.05);

}
.form-row label {
    margin-bottom:0;
}
.form-row .description {
    display:block;
    font-size:70%;
    opacity:.7;
}
</style>