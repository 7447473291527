import { required, minLength } from 'vuelidate/lib/validators';
var axios = require('axios').default;
import { setAuthToken } from '@/auth';
import Vue from 'vue';
export default Vue.extend({
    data: function () {
        return {
            username: null,
            password: null,
            selectedDevice: null,
            loginFailed: false,
            loggingIn: false,
            ready: false,
            devices: {
                deviceList: [],
                any: false
            }
        };
    },
    computed: {
        deviceId: function () {
            var _a;
            return this.devices != null ? (_a = this.selectedDevice) === null || _a === void 0 ? void 0 : _a.deviceID : "me";
        },
        devicesOptions: function () {
            return this.devices.deviceList.map(function (dev) {
                var platform = dev.platformName || '<unassigned>';
                return dev.alias + "@" + platform + " (" + dev.deviceID + ")";
            });
        }
    },
    validations: {
        username: { required: required, minLength: minLength(3) },
        password: { required: required }
    },
    mounted: function () {
        var _this = this;
        this.ready = false;
        axios.get("/api/devices").then(function (res) {
            // se ha restituo un elenco, significa che è possibile selezionare su quale device entrare
            if (res.data.devices) {
                _this.devices.deviceList = res.data.devices;
                _this.devices.any = true;
            }
            else {
                _this.devices.any = false;
            }
            _this.ready = true;
        });
    },
    methods: {
        signIn: function () {
            var _this = this;
            if (this.$v.invalid)
                return;
            if (this.devices.any && !this.selectedDevice) {
                this.showToast('Errore', 'E\' necessario selezionare un dispositivo remoto al quale connettersi', 'danger');
                return;
            }
            this.loginFailed = false;
            this.loggingIn = true;
            axios.post('/api/auth', { username: this.username, password: this.password })
                .then(function (resp) {
                setAuthToken(resp.data.token);
                console.log('Logged to device ' + _this.deviceId);
                _this.$router.push('/device/' + _this.deviceId);
            }).catch(function (resp) {
                // login failed
                _this.loggingIn = false;
                _this.showToast('Login fallito', 'Le credenziali immese non sono valide', 'danger');
            });
        }
    }
});
