import Vue from 'vue';
export default Vue.extend({
    methods: {
        showToast: function (title, content, variant) {
            if (variant === void 0) { variant = 'primary'; }
            this.$bvToast.toast(content, {
                title: title,
                toaster: "b-toaster-bottom-right",
                variant: variant,
                solid: true,
                appendToast: true
            });
        }
    }
});
