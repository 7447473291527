<template>
    <span>
        {{value}}%
        <i class="fa fa-signal ml-1" :class="className"></i>
    </span>
</template>
<script>
export default {
    props:{
        value: {
            type: Number,
            required: true
        }
    },
    computed: {
        className(){
            if (this.value < 33)
                return 'red';
            else if(this.value < 66)
                return 'orange';
            else 
                return 'green';
            
        }
    }
}
</script>