<template>
    <grid :loading="fetching">
        <template v-slot:title>
            <p class="text-center"><i class="fa fa-cloud"></i> Controllo connettività</p>
        </template>
        <div class="text-center">
            <div v-if="!ready">
                <i class="fa fa-circle-notch fa-spin mb-3" style="font-size:4rem"></i>
                <p class="mt-3">
                    Controllo in corso...
                </p>
            </div>
            <div v-else>
                <table class="w-100">
                    <tr>
                        <td class="pr-2">
                            <status-icon :success="result.cloudConnected" size="2.75rem"></status-icon>    
                        </td>
                        <td>
                            <template v-if="result.cloudConnected">
                                <span class="bold d-block green">EcWeb raggiungibile </span>
                                <span class="text-muted fs-80">Tempo di risposta {{result.cloudDelay.totalMilliseconds | number(0)}}ms</span>
                            </template>
                            <template v-else>
                                <span class="red bold d-block"><i class="fa fa-exclamation-triangle"></i> EcWeb non connessa</span>
                                <span class="text-muted fs-80">Questa unità è isolata</span>
                            </template>
                        </td>
                    </tr>
                    <tr>
                        <td class="pr-2"> 
                            <status-icon :success="result.vpnConnected" size="2.75rem"></status-icon>    
                        </td>
                        <td>
                            <template v-if="result.vpnConnected">
                                <span class="bold d-block green">Balena Cloud connesso</span>
                                <span class="text-muted fs-80">Questo datalogger è aggiornato</span>
                            </template>
                            <template v-else>
                                <span class="bold red d-block"><i class="fa fa-exclamation-triangle"></i> Balena Cloud non raggiungibile</span>
                                <span class="text-muted fs-80">Questa unità non è aggiornabile</span>
                            </template>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </grid>
</template>
<script>
const axios = require('axios').default;
export default {
    props:{
        size:{
            default(){
                return '5rem'
            }
        }
    },
    data(){
        return {
            fetching: false,
            ready: false,
            result:{
                cloudConnected:false,
                cloudDelay:null,
                vpnConnected: false,
                status: ''
            }
        }
    },
    computed:{

    },
    mounted(){
        this.check();
    },
    methods:{
        check(){
            this.fetching = true;
            axios.get('/api/cloud/check').then(data => {
                this.fetching = false;
                this.ready = true;
                Object.assign(this.result, data.data);
            });
        },
        refresh(){
            this.check();
        }
    }

}
</script>
<style scoped lang="scss">
table tr td {
    text-align:left;
    padding:.7rem 0;
    align-items: center;
}
table tr:not(:last-child) {
    border-bottom:1px solid #eee;
}

</style>