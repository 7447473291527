<template>
    <div>
        <!-- Table -->
        <b-table
            id="sms-table"
            :class="isBusy ? 'mb-2' : 'mb-1'"
            :fields="fields"
            :items="items"
            :busy="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :per-page="perPage"
            :current-page="currentPage"
            small
            responsive 
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner small class="align-middle mr-2"></b-spinner>
                    <strong>Caricamento...</strong>
                </div>
            </template>
            <template #cell(show_details)="row">
                <b-button size="sm" block @click="row.toggleDetails" :variant="row.detailsShowing ? 'light' : 'primary'" style="min-width:6rem">
                    {{ row.detailsShowing ? 'Nascondi' : 'Dettagli'}}
                </b-button>
            </template>
            <template #row-details="row">
                <b-card class="sms-content-box">
                    {{ row.item.contenuto }}
                </b-card>
            </template>
        </b-table>
        <!-- Table info -->
        <small v-if="!isBusy">
            <span v-if="totalRows > 0">Pagina {{ currentPage }} di {{ pagesCount }} - Sono presenti {{ totalRows }} elementi</span>
            <span v-if="totalRows == 0">Nessun elemento disponibile</span>
        </small>
        <!-- Table paginator -->
        <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="sms-table"
            class="mt-2 mb-0"
            align="center"
        ></b-pagination>
    </div>
</template>
<script>
import moment from "moment";

const axios = require('axios').default;
export default {
    name: 'SMSTable',
    props: {
        smsContentPreviewLength: {
            type: Number,
            require: false,
            default: 50
        },
        perPage: {
            type: Number,
            require: false,
            default: 20
        }
    },
    data(){
        return {
            items: [],
            // synchronous data load
            isBusy: true,
            // pagination
            currentPage: 1,
            // items sorting
            sortBy: "timestamp",
            sortDesc: true,
            // columns definition
            fields: [
                {
                    key: "timestamp",
                    sortable: true,
                    tdClass: "nowrap align-middle shrinked",
                    formatter: (value) => {
                        return moment(value).format("YYYY-MM-DD HH:mm:ss")
                    }
                },
                {
                    key: "mittente",
                    tdClass: "align-middle shrinked",
                    sortable: true
                },
                {
                    key: "contenuto",
                    sortable: false,
                    tdClass: "align-middle single-line"
                },
                {
                    label: "",
                    key: "show_details",
                    tdClass: "shrinked"
                }
            ]
        }
    },
    computed: {
        totalRows() {
            return this.items.length;
        },
        pagesCount() {
            return Math.ceil(this.totalRows / this.perPage);
        }
    },
    methods: {
        refresh() {
            this.isBusy = true;
            axios.get('/api/sms')
                .then((response) => {
                    this.isBusy = false;

                    // map items to a table compliant format
                    this.items = response.data.map(e => {
                        return {
                            timestamp: e.receiveTime, 
                            mittente: e.from,
                            preview: e.text.substring(0, this.smsContentPreviewLength),
                            contenuto: e.text,
                            _showDetails: false
                        }
                    });
                })
                .catch((error) => {
                    this.isBusy = false;

                    this.showToast("Errore", "Non è stato possibile recuperare i dati richiesti. Riprova", "danger");
                    console.error(error);

                    return [];
                });
        }
    },
    mounted() {
        this.refresh();
    }
}
</script>
<style lang="scss">
    table {
        font-size: 9pt;
    }
    .nowrap {
        white-space: nowrap;
    }
    .single-line {
        max-width: 20rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .sms-content-box {
        font-size: 11pt;
    }
    .shrinked {
        width: 0;
        padding: .3rem .4rem !important;
    }
</style>