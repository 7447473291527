<template>
    <grid :loading="fetching" bodyClass="pt-0">
        <template v-slot:title>
            <i class="fa fa-globe-americas"></i> Stato delle connessioni
        </template>

         <div class="row">
            <div class="col-12 col-sm-6 connection" v-for="conn of connections" :key="conn.name">
                <connection-status-viewer :connection="conn"></connection-status-viewer>
            </div>
        </div>
    </grid>
   
</template>
<script>
const axios = require('axios').default;
import ConnectionStatusViewer from './ConnectionStatusViewer.vue';
export default {
    components:{
        ConnectionStatusViewer
    },
    data(){
        return {
            connections:[],
            fetching: false,
            ready: false
        }
    },
    mounted(){
        this.fetch();
    },
    methods: {
        
        fetch(){
            this.fetching = true;
            axios.get('/api/network/connection/state').then(result => {
                this.connections = result.data;
                this.fetching = false;
                this.ready = true;
            });
        },
        refresh(){
            // parent-called method - do not change name
            this.fetch();
        }
    }
}
</script>
<style lang="scss" scoped>
.connection {
    border-right:1px solid #eee;
    border-bottom:1px solid #eee;
}
</style>