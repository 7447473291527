<template>
    <div>
        <page-title><i class="fa fa-sim-card"></i> SMS</page-title>
        <grid :loading="false">
            <b-row class="mb-3">
                <b-col>
                    <eci-button icon="fa-sync" class="mr-2" @click="refreshTable()">Aggiorna</eci-button>
                </b-col>
                <b-col class="text-right">
                    <eci-button icon="fa-trash" variant="dark" @click="clearAskConfirm()">Elimina tutto</eci-button>
                </b-col>
            </b-row>
            <sms-table ref="smsTable"></sms-table>
        </grid>
    </div>
</template>
<script>
import Grid from '@/common/components/Grid.vue'
import EciButton from '@/common/components/EciButton.vue'
import SMSTable from "@/components/sms/SMSTable.vue"

const axios = require('axios').default;
export default {
    name: 'SMS',
    components: {
        'sms-table': SMSTable,
        EciButton,
        Grid
    },
    methods: {
        refreshTable() {
            this.$refs.smsTable.refresh();
        },
        clearAskConfirm() {
            if (!confirm("Confermi di volere rimuovere tutti gli SMS presenti sul sistema?"))
                return;
            // clear SMSs and force table update 
            axios.delete('/api/sms')
                .then(_ => this.refreshTable())
        }
    }
}
</script>
<style scoped>

</style>