<template>
    <div id="plugins-viewer">
        <page-title><i class="fa fa-puzzle-piece"></i> Plugin</page-title>
        <grid bodyClass="half-pb">
            <b-nav pills class="plugin-navigator">
                <b-skeleton-wrapper :loading="loading">
                    <template #loading>
                        <b-skeleton width="4rem"></b-skeleton>
                        <b-skeleton width="9rem"></b-skeleton>
                        <b-skeleton width="6rem"></b-skeleton>
                    </template>
                    <b-nav-item v-for="(info, name, index) in plugins" :key="index" :active="name == activePlugin" @click="activePlugin = name">
                        {{ name }}
                    </b-nav-item>
                </b-skeleton-wrapper>
                <eci-button icon="fa-plus" variant="success" @click="openAddPluginModal()">Aggiungi</eci-button>
            </b-nav>
        </grid>

        <plugin-detail v-if="activePlugin != null" :name="activePlugin" :info="plugins[activePlugin]" class="mt-2"></plugin-detail>
    </div>
</template>
<script>
import PluginUploader from '@/components/plugins/PluginUploader.vue';
import PluginDetail from '@/components/plugins/PluginDetail.vue';
import { openModal } from '@/common/components/EciModal';

const axios = require('axios').default;
export default {
    name: 'Plugins',
    data() {
        return {
            loading: true,
            plugins: [],
            activePlugin: null
        }
    },
    components: {
        PluginDetail
    },
    mounted() {
        axios.get('/api/plugins')
            .then(result => {
                this.loading = false;
                this.updatePlugins(result.data, null);
            });
    },
    methods: {
        openAddPluginModal(){
            openModal('Caricamento plugin', PluginUploader)
                .then(result => {
                    this.updatePlugins(result.data, this.activePlugin);
                });
        },
        updatePlugins(pluginsArray, activePlugin) {
            if (pluginsArray && pluginsArray.length > 0)
            {
                // update plugins dictionary
                this.plugins = {};
                pluginsArray.forEach(pi => {
                    this.plugins[pi.name] = {
                        version: pi.version,
                        pluginType: pi.pluginType
                    };
                });
                // update active plugin
                this.activePlugin = (activePlugin && activePlugin in this.plugins) ?
                    activePlugin : 
                    pluginsArray[0].name;
            }
            else
            {
                this.plugins = [];
                this.activePlugin = null;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .plugin-navigator > * {
        margin-bottom: .5rem;
    }
    .b-skeleton {
        display: inline-block;
        margin-right: .75rem;
        border-radius: 999px;
        height: 100%;
    }
    .nav-item {
        font-size: .9rem;
        margin-right: .75rem;

        a {
            border-radius: 999px;
            font-family: 'Montserrat';

            color: var(--dark);
            border: 1px solid var(--dark);
            transition: .15s;

            &.active, &:not(.active):hover {
                color: white;
                background-color: var(--dark);
            }
            &.active:hover {
                background-color: #212529;
            }
        }
    }
</style>