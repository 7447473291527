<template>
    <div>
        <grid>
            <b-row>
                <b-col>
                    <div class="plugin-name">
                        <h4>{{ name }}</h4>
                        <h5><b-badge class="ml-2" variant="primary">writer3</b-badge></h5>
                    </div>
                    <p class="mb-0"><small>Versione: {{ info.version }}</small></p>
                </b-col>
            </b-row>
            <hr />
            <h5 class="test-settings-title mb-3">Impostazioni di test</h5>
            <b-form>
                <b-row>
                    <b-col xl=6 class="mb-3">
                        <!-- Field device settings -->
                        <b-card title="Strumento" title-tag="h5" bg-variant="light">
                            <b-form-group
                                class="mb-2"
                                label="IP"
                                label-size="sm"
                                label-class="mb-0"
                                label-for="device-ip"
                            >
                                <b-form-input
                                    v-model="$v.testSettings.device.target.$model"
                                    type="text"
                                    id="device-ip" 
                                    size="sm"
                                    :disabled="testRunning"></b-form-input>
                            </b-form-group>
                            <b-form-group
                                class="mb-2"
                                label="Codice chiamata"
                                label-size="sm"
                                label-class="mb-0"
                                label-for="device-call-arguments"
                            >
                                <b-form-input
                                    v-model="$v.testSettings.device.readingConfiguration.callArguments.$model"
                                    type="text"
                                    id="device-call-arguments"
                                    size="sm"
                                    :disabled="testRunning"></b-form-input>
                            </b-form-group>
                            <b-form-group
                                class="mb-0"
                                label="Codice risposta"
                                label-size="sm"
                                label-class="mb-0"
                                label-for="device-response-arguments"
                            >
                                <b-form-input
                                    v-model="$v.testSettings.device.readingConfiguration.responseArguments.$model"
                                    type="text"
                                    id="device-response-arguments"
                                    size="sm"
                                    :disabled="testRunning"></b-form-input>
                            </b-form-group>
                            </b-card>
                    </b-col>
                    <!-- Channel settings -->
                    <b-col xl=6 class="mb-3">
                        <b-card title="Canale" title-tag="h5" bg-variant="light">
                            <b-form-group
                                class="mb-2"
                                label="Registro"
                                label-size="sm"
                                label-class="mb-0"
                                label-for="channel-register"
                            >
                                <b-form-input
                                    v-model="$v.testSettings.callInfo.readingConfiguration.register.$model"
                                    type="number"
                                    id="channel-register"
                                    size="sm"
                                    min="0"
                                    :disabled="testRunning"></b-form-input>
                            </b-form-group>
                            <b-form-group
                                class="mb-2"
                                label="Codice chiamata"
                                label-size="sm"
                                label-class="mb-0"
                                label-for="channel-call-arguments"
                            >
                                <b-form-input
                                    v-model="$v.testSettings.callInfo.readingConfiguration.callArguments.$model"
                                    type="text"
                                    id="channel-call-arguments"
                                    size="sm"
                                    :disabled="testRunning"></b-form-input>
                            </b-form-group>
                            <b-form-group
                                class="mb-0"
                                label="Codice risposta"
                                label-size="sm"
                                label-class="mb-0"
                                label-for="channel-response-arguments"
                            >
                                <b-form-input 
                                    v-model="$v.testSettings.callInfo.readingConfiguration.responseArguments.$model"
                                    type="text"
                                    id="channel-response-arguments" 
                                    size="sm"
                                    :disabled="testRunning"></b-form-input>
                            </b-form-group>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right">
                        <eci-button icon="fa-vial" :disabled="this.$v.$invalid || testRunning" :loading="testRunning" @click="runTest()">
                            Test configurazione
                        </eci-button>
                    </b-col>
                </b-row>
            </b-form>
        </grid>
        <plugin-test-result
            v-if="testResults != null && !testRunning"
            :results="testResults"
            :timestamp="testExecutionTimestamp"
        ></plugin-test-result>
    </div>
</template>
<script>
import moment from "moment"
import { required, ipAddress, minValue } from 'vuelidate/lib/validators';
import EciButton from '@/common/components/EciButton.vue';
import PluginTestResult from './PluginTestResult.vue';

const axios = require('axios').default;
export default {
    name: 'PluginDetail',
    components: {
        EciButton,
        PluginTestResult
    },
    props: {
        name: { type: String, require: true },
        info: { type: Object, require: true }
    },
    data() {
        return {
            testRunning: false,
            testSettings: {
                device: {
                    target: null,//"192.168.0.7",
                    readingConfiguration: {
                        callArguments: null,//"node=5",
                        responseArguments: null//""
                    }
                },
                callInfo: {
                    guid: "",
                    kFactor: 1,
                    formula: "", 
                    readingConfiguration: {
                        register: null,//4,
                        callArguments: null,//"3",
                        responseArguments: null//"Tipo=INT16;endianness=NOSWAP"
                    }
                }
            },
            testResults: null,
            testExecutionTimestamp: null
        }
    },
    validations(){
        return {
            testSettings: {
                device: {
                    target: { required },
                    readingConfiguration: {
                        callArguments: {  },
                        responseArguments: {  }
                    }
                },
                channel: {
                    readingConfiguration: {
                        register: { required, minValue: minValue(0) },
                        callArguments: {  },
                        responseArguments: {  }
                    }
                }
            }
        };
    },
    computed: {
        pluginBadgeVariant() {
            switch (this.info.type)
            {
                case "optimus": return "danger";
                case "writer3": return "primary";
                default: return null;
            }
        },
        
    },
    methods: {
        runTest() {
            if (this.$v.$invalid)
                return;

            // preprocess payload
            let payload = this.testSettings;
            // --> insert selected device type
            payload.device.deviceType = this.info.pluginType;
            // --> parse channel register as integer
            let r = payload.channel.readingConfiguration.register;
            payload.channel.readingConfiguration.register = parseInt(r);

            // --> insert K multiplier to 1 (static)
            payload.channel.kFactor = 1;

            this.testRunning = true;
            let start = new Date();
            axios.post('/api/plugins/test', payload)
                .then(result => {
                    this.testRunning = false;
                    this.testResults = result.data;
                    this.testExecutionTimestamp = start;
                })
                .catch(error => {
                    this.testRunning = false;
                    this.testResults = null;
                    this.showToast("Errore", "Si è verificato un errore durante l'esecuzione del test. Riprova", "danger");
                    console.error(error);
                });
        }
    }
}
</script>
<style lang="scss" scoped>
    .test-settings-title {
        font-weight: normal;
    }
    .plugin-name {
        & > * {
            display: inline-block;
            margin-bottom: 0!important;
        }
        .badge {
            position: relative;
            top: -.2rem;
        }
    }
</style>