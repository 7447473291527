<template>
    <div id="navbar" class="d-flex align-items-center justify-content-between px-3 ">
        <div class="row fs-90 w-100">
            <div class="col-auto my-auto">
                <span class="fa fa-bars sidebar-toggle" @click="toggleSidebar()"></span>
            </div>
          
            <div class="col-auto ">
                <h5 class="alt mb-0">{{identity.alias}} - {{identity.platformName | coalesce('Non assegnato')}}</h5>
                <span class="fs-80 text-muted">#{{deviceInfo.deviceName}} @ {{deviceInfo.deviceType}}</span>
                
            </div>
            <div class="col text-right text-muted fs-90 my-auto">
                <div class="d-none d-md-block">
                    <p class="my-0">
                        Latenza media: {{quality.transportTimeAvg | coalesce('n.a') | number('0')}}ms
                    </p>
                    <p class="my-0" v-if="deviceClock">
                        <i class="fa fa-clock mr-1"></i> {{deviceClock | date}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    const axios = require('axios').default;
    export default {
        name: 'navbar',
        data(){
            return {
                deviceClock: null,
                quality:{
                    transportTimeAvg: null
                }
            }
        },
        methods: {
            toggleSidebar() {
                this.$emit('sidebar-toggle', null);
            },
            syncDeviceClock(t){
                // every X seconds updates its own clock with the server
                const self = this;
                setTimeout(function(){
                    axios.get('/api/host/time').then(res => {
                        self.deviceClock = new Date(res.data);
                        self.syncDeviceClock(5000);
                    });
                }, t);
            },
            updateClock(){
                // moves the clock 1 sec forward
                if (this.deviceClock){
                    this.deviceClock.setSeconds(this.deviceClock.getSeconds() + 1);
                    this.deviceClock = new Date(this.deviceClock); // <--- triggers the reactivity of Vue
                }
            },
            updateTransportQuality(t){
                const self = this;
                setTimeout(function(){
                    axios.get('/api/rpc/quality').then(res => {
                        Object.assign(self.quality, res.data);
                    });
                    // repeat after X secs, it's safe to alwasy do it because NAVBAR is a global component with a flat lifetime
                    self.updateTransportQuality(5000);
                }, t)
            }
        },
        computed: {
            device(){
                return this.$store.state.device;
            },
            deviceInfo(){
                return this.$store.state.deviceInfo;
            },
            identity(){
                return this.$store.state.identity;
            }
        },
        mounted(){
            const self = this;
            setInterval(function(){
                self.updateClock();
            }, 1000);
            // start the sync cycle
            this.syncDeviceClock(0);

            // start the quality info cycle
            this.updateTransportQuality(0);
        },
        destroyed(){
            clearInterval(this._timer);
        }

    }
</script>
<style scoped>
    #navbar {
        width:100%;
        height:55px;
        position:fixed;
        top:0;
        left:0px;
        background-color:#fff;
        z-index:99;
      
    }
    #navbar-logo {
        height:40px;
    }
    .sidebar-toggle {
        font-size:1.2rem;
        cursor:pointer;
        margin-left:.15rem;
    }
</style>