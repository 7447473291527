import { render, staticRenderFns } from "./EventsSummary.vue?vue&type=template&id=aeaee848&scoped=true&"
import script from "./EventsSummary.vue?vue&type=script&lang=js&"
export * from "./EventsSummary.vue?vue&type=script&lang=js&"
import style0 from "./EventsSummary.vue?vue&type=style&index=0&id=aeaee848&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aeaee848",
  null
  
)

export default component.exports