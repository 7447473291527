<template>
    <span class="fa" :class="icon"></span>
</template>
<script>
import { IFaceType } from '@/models/Network';
export default {
    props:['type'],
    computed:{
        icon(){
            if (this.type == IFaceType.Eth)
                return 'fa-ethernet';
            else if (this.type == IFaceType.WiFi)
                return 'fa-wifi';
            else if (this.type == IFaceType.GSM)
                return 'fa-signal';
        }
    }
}
</script>