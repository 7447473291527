import ChannelDisplay from './ChannelDisplay.vue';
import Vue from 'vue';
export default Vue.extend({
    name: 'workload-tree-node',
    components: {
        ChannelDisplay: ChannelDisplay
    },
    props: {
        node: {
            type: Object,
            required: true
        },
        values: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },
    data: function () {
        return {
            open: true
        };
    },
    computed: {
        isGateway: function () {
            return !!this.node.children && this.node.children.length > 0;
        },
        title: function () {
            return "".concat(this.node.device.target, " - ").concat(this.node.device.readingConfiguration.callArguments, " - ").concat(this.node.device.readingConfiguration.responseArguments);
        },
        state: function () {
            var expectedOk = this.node.channels.length;
            // if it's a gateway, add the children to the expected ok values
            if (this.isGateway && this.node.children)
                expectedOk += this.node.children.reduce(function (p, c) { return p + c.channels.length; }, 0);
            // if there is nothing here, state is neither ok or nok
            if (expectedOk == 0)
                return 'uknown';
            // count how many correct values are there
            var ok = 0;
            for (var k in this.values)
                ok += this.values[k].isOk ? 1 : 0;
            // and there compare them with the expected correct values
            if (expectedOk == ok)
                return 'ok';
            else if (ok == 0) // here, expectedOk cannot be zero
                return 'nok';
            else
                return 'misc';
        }
    },
    methods: {
        value: function (ch) {
            // this.values is a dict
            return this.values[ch.id];
        },
        toggle: function () {
            this.open = !this.open;
        }
    }
});
