
export const IFaceType = {
    Eth: 0,
    WiFi: 1,
    GSM: 2,

    ToString(type){
        switch(type){
            case IFaceType.Eth:
                return "Ethernet (wired)";
            case IFaceType.WiFi:
                return "Wifi";
            case IFaceType.GSM:
                return "3G/4G Mobile";
            default:
                return '<other>';
        }
    }
}

export const IFaceStatus = {
    Disconnected: 0,
    Connecting: 1,
    NeedAuth: 2,
    Connected: 3,
    Unknown: 4
}