<template>
    <div>
        <page-title icon="fa-wrench">Operazioni</page-title>
        <grid>
            <template v-slot:title>Operazioni</template>

            <form-row label="Riavvia il dispositivo" description="Effettua un riavvio del datalogger">
                <eci-button icon="fas fa-redo" :disabled="fetching" @click="reboot()">Riavvia</eci-button>
            </form-row>
            <form-row label="Spegni il dispositivo" description="Attenzione! Questa operazione richiederà l'accessione manuale per ripristinare il dispositivo">
                <eci-button icon="fas fa-power-off" :disabled="fetching" @click="shutdown()">Spegni</eci-button>
            </form-row>
            <form-row label="Riavvia il modem" description="Utile in caso di problemi di connettività con il provider 4G sulla rete mobile">
                <eci-button icon="fas fa-broadcast-tower" :disabled="fetching" @click="resetModem()">Riavvia</eci-button>
            </form-row>
            <form-row label="Abilitazione letture" description="Permette di disattivare temporaneamente tutte le letture di questo dispositivo">
                <work-status-toggler></work-status-toggler>
            </form-row>
            <hr/>
        </grid>
        <grid class="mt-3">
            <template v-slot:title>Ping</template>
            <form-row label="Effettua un Ping" description="Permette di effettuare un ping su un dispositivo locale">
                <div class="row">
                    <div class="col">
                        <b-form-input placeholder="IP" type="text" v-model="ping.ip"></b-form-input>
                        <p v-if="ping.pingResult.hasResult && !ping.pinging">
                            # Risposte ok: {{ping.pingResult.okReplies}} - # Richiste fallite {{ping.pingResult.failedReplies}}
                        </p> 
                    </div>
                    <div class="col-auto">
                        <eci-button icon="fas fa-share" @click="pingIP()" :disabled="ping.pinging" :loading="ping.pinging">Ping</eci-button>
                    </div>
                </div>
            </form-row>
            <!-- PING RESULT -->
        </grid>
    </div>
</template>
<script>
import WorkStatusToggler from '@/components/workload/WorkStatusToggler.vue';
const axios = require('axios').default;
export default {
    components:{ WorkStatusToggler},
    data(){
        return {
            fetching:false,
            ping:{
                ip: '',
                pinging: false,
                pingResult: {
                    hasResult: false,
                    failedReplies:0,
                    okReplies:0
                }
            }
        }
    },
    methods:{
        reboot(){
            if (!confirm('Sei sicuro di voler riavviare il dispositivo?'))
                return;
            this.fetching = true;
            axios.post("/api/host/reboot").then(res => {
                this.fetching = false;
                this.$bvToast.toast("Dispositivo in riavvio...", { title:'Riavvio'});
            });
        },
        shutdown(){
            if (!confirm("Sei sicuro di voler spegnere questo dispositivo? Dovrà essere riacceso fisicamente!"))
                return;
            this.fetching = true;
            axios.post("/api/host/shutdown").then(res => {
                this.fetching = false;
                this.$bvToast.toast("Dispositivo in spegnimento...", { title:'Spegnimento'});
            });
        },
        resetModem(){
            this.fetching = true;
            axios.post("/api/host/modem/reset").then(res => {
                this.fetching = false;
                this.$bvToast.toast("Reset del modem in corso...", { title:'Reset Modem'});
            });
        },
        pingIP(){
            this.ping.pinging = true;
            axios.get('/api/host/pingip', { params: { ip : this.ping.ip } }).then(res => {
                this.ping.pinging = false;
                Object.assign(this.ping.pingResult, res.data);
                this.ping.pingResult.hasResult = true;
            });
        }
    }
}
</script>