import Vue from 'vue';
import { EciStatusCode } from '@/models/realtime-value';
import { openModal } from '@/common/components/EciModal.vue';
import RealtimeSamplesViewer from '@/components/workload/RealtimeSamplesViewer.vue';
function trimZeroes(str) {
    var i = str.length - 1;
    while (i >= 0) {
        if (str[i] != '0')
            break;
        i--;
    }
    return str.substr(0, i);
}
export default Vue.extend({
    props: {
        channel: {
            default: null,
            required: true,
            type: Object
        },
        value: {
            default: null,
            type: Object
        }
    },
    data: function () {
        return {
            anim: {
                active: false,
                timer: null
            }
        };
    },
    watch: {
        value: function (prev, curr) {
            if ((!prev) != (!curr))
                this.highlight();
            else if ((prev === null || prev === void 0 ? void 0 : prev.timestamp) != (curr === null || curr === void 0 ? void 0 : curr.timestamp))
                this.highlight();
        }
    },
    computed: {
        title: function () {
            var _a, _b, _c;
            return "Registro ".concat((_a = this.channel) === null || _a === void 0 ? void 0 : _a.readingConfiguration.register, ". Codice Chiamata: ").concat((_b = this.channel) === null || _b === void 0 ? void 0 : _b.readingConfiguration.callArguments, ". Codice Risposta: ").concat((_c = this.channel) === null || _c === void 0 ? void 0 : _c.readingConfiguration.responseArguments);
        },
        state: function () {
            if (!this.value)
                return 'unknown';
            else
                return this.value.isOk ? 'ok' : 'nok';
        },
        errorName: function () {
            return EciStatusCode[this.value.statusCode];
        },
        dec: function () {
            var value = this.value.value;
            var str = value.toFixed(5);
            return trimZeroes(str.split('.')[1]);
        },
        hasDec: function () {
            return this.dec.length > 0;
        },
        integer: function () {
            var value = this.value.value;
            var part = value.toFixed().split('.')[0];
            var formatted = '';
            var digits = 0;
            for (var i = part.length - 1; i >= 0; i--) {
                if (digits == 3) {
                    formatted = ' ' + formatted;
                    digits = 0;
                }
                digits++;
                formatted = part[i] + formatted;
            }
            return formatted;
        },
        datetime: function () {
            if (this.value)
                return new Date(this.value.timestamp);
            return null;
        }
    },
    methods: {
        highlight: function (time) {
            var _this = this;
            if (time === void 0) { time = 2500; }
            if (this.anim.timer)
                clearTimeout(this.anim.timer);
            this.anim.active = true;
            setTimeout(function () { return _this.anim.active = false; }, time);
        },
        openChart: function (channel) {
            openModal('Campionamenti recenti', RealtimeSamplesViewer, { channel: channel }, { size: 'xl' });
        }
    }
});
