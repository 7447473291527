<template>
    <button type="button" class="btn" :class="variantClass" @click="$emit('click')" :disabled="loading">
        <slot/>
        <span class="icon">
            <i class="fa" :class="icon" v-show="!loading"></i>
            <i class="fa fa-circle-notch fa-spin" v-show="loading"></i>
        </span>
    </button>
</template>
<script>
export default {
    props:{
        variant: {
            type: String,
            default(){
                return 'primary';
            }
        },
        icon: {
            type: String
        },
        loading: {
            type: Boolean
        }
    },
    computed:{
        variantClass(){
            return 'btn-'+this.variant;
        }
    }
}
</script>
<style scoped lang="scss">
    .icon {
        font-size:.9rem;
        background-color:#1118;
        border-radius:50%;
        width:2rem;
        height:2rem;
        display:inline-flex;
        align-items:center;
        justify-content:center;
        margin-left:.9rem;
        transition:color ease-in-out .15s;
    }
    .btn {
        padding:2px;
        padding-left:1rem;
        transition:background-color ease-in-out .15s;

        &:not(:disabled):not(.disabled):hover .icon {
            background-color:darken(#333, 10%);
            color:#b90000;
        }
    }
</style>