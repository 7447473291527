<template>
    <div>
        <!-- Search -->
        <b-input-group size="sm" class="mb-3">
            <b-input-group-prepend is-text>
                <i class="fa fa-search"></i>
            </b-input-group-prepend>
            <b-form-input 
                type="text" 
                size="sm" 
                placeholder="Cerca..." 
                v-model="search" 
                debounce="250"
            ></b-form-input>
        </b-input-group>
        <!-- Table -->
        <b-table
            :id="id"
            :class="isBusy ? 'mb-2' : 'mb-1'"
            :fields="fields"
            :items="eventsProvider"
            :busy="isBusy"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :per-page="perPage"
            :current-page="currentPage"
            :filter="search"
            small
            responsive 
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner small class="align-middle mr-2"></b-spinner>
                    <strong>Caricamento...</strong>
                </div>
            </template>
        </b-table>
        <!-- Table info -->
        <small v-if="!isBusy">
            <span v-if="filteredRows > 0">Pagina {{ currentPage }} di {{ pagesCount }} - Sono presenti {{ filteredRows }} elementi</span>
            <span v-if="filteredRows == 0">Nessun elemento disponibile</span>
        </small>
        <!-- Table paginator -->
        <b-pagination
            v-model="currentPage"
            :total-rows="filteredRows"
            :per-page="perPage"
            :aria-controls="id"
            class="mt-2 mb-0"
            align="center"
        ></b-pagination>
    </div>
</template>
<script>
import { EventSeverity } from "@/models/Events.js"
import moment from "moment"

function mapSeverityVariant(s)
{
    switch (s) {
        case EventSeverity.Debug:
        case EventSeverity.Trace:
            return "light";
        case EventSeverity.Information:
            return "info";
        case EventSeverity.UserError:
            return "dark";
        case EventSeverity.SystemError:
            return "warning";
        case EventSeverity.Critical:
            return "danger"
        default:
            return null;
    }
}

const axios = require('axios').default;
export default {
    name: 'EventsTable',
    props: {
        id: { type: String, require: true },
        from: { type: Date },
        to: { type: Date },
        severity: { type: Number },
        perPage: { type: Number }
    },
    data(){
        return {
            // async data load
            isBusy: false,
            // pagination
            currentPage: 1,
            filteredRows: 0,
            // filtering
            search: null,
            // items sorting
            sortBy: "timestamp",
            sortDesc: true,
            // columns definition
            fields: [
                {
                    key: "timestamp",
                    sortable: true,
                    tdClass: "nowrap",
                    formatter: (value) => {
                        return moment(value).format("YYYY-MM-DD HH:mm:ss")
                    }
                },
                {
                    key: "sorgente",
                    sortable: false
                },
                {
                    key: "descrizione",
                    sortable: false
                },
                {
                    key: "severity",
                    sortable: false
                }
            ]
        }
    },
    computed: {
        pagesCount() {
            return Math.ceil(this.filteredRows / this.perPage);
        }
    },
    methods: {
        async eventsProvider(ctx, callback) {
            // set table status as busy
            this.isBusy = true;

            // retrieve rows
            let filterParams = {
                from: this.from,
                to: this.to,
                severity: this.severity,
                filter: ctx.filter,
            };
            let params = {
                ...filterParams,
                sortDesc: ctx.sortDesc,
                skip: (ctx.currentPage - 1) * ctx.perPage,
                take: ctx.perPage
            };

            let items = axios.get('/api/events/events', { params })
                .then((response) => {
                    this.isBusy = false;
                    // map items to a table compliant format
                    const items = response.data.events.map(e => {
                        var severityKey = Object.keys(EventSeverity).find(key => EventSeverity[key] === e.severity);
                        return {
                            timestamp: e.eventTime, 
                            sorgente: e.source,
                            descrizione: e.description,
                            severity: severityKey,
                            _rowVariant: mapSeverityVariant(e.severity)
                        }
                    });

                    // update filtered rows count
                    this.filteredRows = response.data.filteredRows;

                    return (items);
                })
                .catch((error) => {
                    this.isBusy = false;

                    this.showToast("Errore", "Non è stato possibile recuperare i log richiesti. Riprova", "danger");
                    console.error(error);

                    return [];
                });

            return items;
        },
    }
}
</script>
<style lang="scss">
    table {
        font-size: 9pt;
    }
    .nowrap {
        white-space: nowrap;
    }
</style>