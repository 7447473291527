<template>
    <div class="grid simple">
        <div v-if="hasTitle" class="grid-title" :class="{'with-buttons':hasButtons}">
            <div class="title-container">
                    <slot name="title"></slot>
            </div>
            
            <b-dropdown variant="link" no-caret right v-if="hasButtons">
                <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                </template>
                <slot name="buttons"></slot>
            </b-dropdown>
            
        </div>
        <div class="grid-body" :class="additionalBodyClasses">
            <span id="body-spinner" class="fa fa-circle-notch fa-spin" v-show="!hasTitle && loading"></span>
            <slot/>
        </div>
        <slot name="control"></slot>
    </div>
</template>
<script>
    import Vue from 'vue';
    export default Vue.extend({
        name: 'grid',
        props:{
            bodyClass: {
                type: String | Array
            },
            loading: {
                type: Boolean
            }
        },
        computed: {
            additionalBodyClasses() {
                var cls = [];
                if (this.bodyClass) {
                    if (Array.isArray(this.bodyClass))
                        cls = [...cls, ...this.bodyClass];
                    else 
                        cls.push(this.bodyClass);
                }
                if (!this.title)
                    cls.push("no-heading");
                
                return cls.join(" ");
            },
            hasButtons(){
                return !!this.$slots['buttons'];
            },
            hasTitle(){
                return !!this.$slots['title'];
            }
        }
    });
</script>
<style scoped lang="scss">
    .grid {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
        background-color:#fff;
        border-radius:.3rem;
    }
    .grid .grid-title {
        padding:1rem 1rem;
        font-weight: 600;
        color: #808487;
        
        .title-container {
            width:100%;
            padding-right:1rem;
        }

        p {
            margin-bottom:0;
        }


        &.with-buttons {
            position:relative;


            .dropdown {
                position:absolute;
                right:1rem;
                top:1rem;
                /deep/ .dropdown-toggle {
                    padding:0;
                    color:#444;
                }
            }
        }
        
    }
    .grid .grid-body {
        padding:1rem;
        
        &:not(.no-heading) {
            padding-top:0;
        }
        &.half-pb {
            padding-bottom:.5rem;
        }
    }
    #body-spinner {
        position: absolute;
        margin-top: .25rem;
        right: 1.2rem;
    }
</style>