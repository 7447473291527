<script>
import { Doughnut, mixins } from 'vue-chartjs';
export default {
    extends: Doughnut,
    mixins:[mixins.reactiveProp],
    props:['chartData','options'],
    mounted(){
        this.updateChart();
    },
    methods:{
        updateChart(){
            this.renderChart(this.chartData, this.options);
        }
    }
}
</script>