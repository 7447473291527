<template>
    <div class="row align-items-center no-gutters">
        <div class="col-auto label">
            {{value}} %
        </div>
        <div class="col">
            <b-progress>
                <b-progress-bar :value="value" :class="colorClass"></b-progress-bar>
            </b-progress>
        </div>
    </div>
</template>
<script>
export default {
    name: 'signal',
    props:['value'],
    computed:{
        colorClass(){
            if (this.value < 33)
                return 'bad';
            else if (this.value < 66)
                return 'normal';
            else if (this.value >= 66)
                return 'good';
        }
    }
}
</script>
<style scoped>
.label {
    margin-right:1rem;
}
.row {
    font-size:.9rem;
}
.progress {
    height:.8rem;
}
.progress-bar.bad {
    background-color:#F44336;
}
.progress-bar.normal {
    background-color:#FFC107;
}
.progress-bar.good {
    background-color:#4CAF50;
}
</style>