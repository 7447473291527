import { setAuthToken } from '@/auth';
import { PermissionLevel } from '@/models/user-session';
import Vue from "vue";
export default Vue.extend({
    data: function () {
        return {
            links: [
                { path: '', name: 'Riepilogo Stato', icon: 'fa-desktop' },
                { path: 'settings', name: 'Impostazioni di rete', icon: 'fa-cogs' },
                { path: 'events', name: 'Eventi e Log', icon: 'fa-th-list' },
                { path: 'workload', name: 'Carico di lavoro', icon: 'fa-database', guests: true },
                { path: 'plugins', name: 'Plugin', icon: 'fa-puzzle-piece' },
                { path: 'operations', name: 'Strumenti e Diagnostica', icon: 'fa-wrench' },
                { path: 'sms', name: 'SMS', icon: 'fa-sim-card' },
            ]
        };
    },
    props: ['open'],
    computed: {
        visibleLinks: function () {
            var _a;
            var permissions = (_a = this.getCurrentUser()) === null || _a === void 0 ? void 0 : _a.permission;
            var isGuest = permissions != PermissionLevel.Admin;
            var links = this.links.filter(function (l) { return isGuest ? l.guests === true : true; });
            return links;
        }
    },
    methods: {
        signOut: function () {
            setAuthToken(null);
            this.$router.push('/login');
        }
    }
});
