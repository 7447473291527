<template>
    <div>
        <b-form-file
            v-model="file"
            browse-text="Sfoglia"
            placeholder="Seleziona un plugin, oppure trascina qui il file..."
            drop-placeholder="Rilascia il file..."
            no-drop-placeholder="File non supportato"
            accept=".writer3"
        ></b-form-file>
        <div v-if="file != null">
            <hr />
            <h5 class="mb-1">
                <span class="plugin-name">{{ pluginName }}</span>
                <b-badge class="ml-2" :variant="pluginBadgeVariant">{{ pluginType }}</b-badge>
            </h5>
            <p><small>Data di ultima modifica: {{ pluginLastModifiedDate }}</small></p>
            <div class="text-right">
                <eci-button icon="fa-upload" :loading="uploading" @click="uploadFile()">Carica</eci-button>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import moment from 'moment';

const axios = require('axios').default;
export default Vue.extend({
    data() {
        return {
            file: null,
            uploading: false
        }
    },
    computed: {
        pluginName() {
            return this.file != null && this.file.name != null ? this.file.name.split('.')[0] : null;
        },
        pluginType() {
            return this.file != null && this.file.name != null ? this.file.name.split('.')[1] : null;
        },
        pluginBadgeVariant() {
            switch (this.pluginType)
            {
                case "writer3": return "primary";
                default: return null;
            }
        },
        pluginLastModifiedDate() {
            return moment(this.file.lastModifiedDate).format("YYYY-MM-DD HH:mm:ss");
        }
    },
    methods: {
        uploadFile() {
            // prepare data
            let formData = new FormData();
            formData.append("file", this.file);
            // perform upload
            this.uploading = true;
            axios.post('/api/plugins', formData)
                .then(result => {
                    this.uploading = false;
                    this.$emit("complete", result);
                    this.showToast("Caricamento completato", 'Il plugin "' + this.file.name + '" è stato caricato con successo', "success");
                })
                .catch(error => {
                    this.uploading = false;
                    this.showToast("Errore", "Non è stato possibile caricare il file desiderato. Riprova", "danger");
                    console.error(error);
                });
        }
    }
})
</script>
<style scoped>
</style>