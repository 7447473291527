var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from 'vue';
import LineChart from '@/components/charts/LineChart.vue';
import { ChannelType } from '@/models/channel';
import { avg } from '@/utils/array-utils';
var axios = require('axios').default;
export default Vue.extend({
    components: { LineChart: LineChart },
    props: {
        channel: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            ChannelType: ChannelType,
            rows: [],
            chartOptions: {
                animation: false,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                            type: 'time'
                        }]
                },
                legend: {
                    display: false
                }
            },
            utils: {
                timer: null
            },
            state: {
                ready: false,
                loading: false
            }
        };
    },
    watch: {
        id: function () {
            // react to change in ID from external
            this.loadData();
        }
    },
    mounted: function () {
        var _this = this;
        this.loadData();
        this.utils.timer = setInterval(function () { return _this.loadData(); }, 5000);
    },
    destroyed: function () {
        clearInterval(this.utils.timer);
    },
    computed: {
        chartData: function () {
            var data = {
                datasets: [{
                        label: "[".concat(this.channel.id, "] ").concat(this.channel.description),
                        borderColor: '#90CAF9',
                        backgroundColor: 'rgba(144, 202, 249, 0.2)',
                        data: this.rows.map(function (row) { return ({ x: new Date(row.timestamp).valueOf(), y: row.value }); })
                    }]
            };
            return data;
        },
        ascendingRows: function () {
            console.log('Sorting');
            // avoid mutating the array in place
            return __spreadArray([], this.rows, true).sort(function (a, b) { return a.value - b.value; });
        },
        min: function () {
            if (this.rows.length == 0)
                return null;
            return this.ascendingRows[0];
        },
        max: function () {
            if (this.rows.length == 0)
                return null;
            return this.ascendingRows[this.ascendingRows.length - 1];
        },
        avg: function () {
            return avg(this.rows, function (r) { return r.value; });
        },
        lastValue: function () {
            if (this.rows.length == 0)
                return null;
            return this.rows[this.rows.length - 1];
        },
        avgIncPerSecond: function () {
            if (this.channel.type != ChannelType.Counter || this.rows.length == 0)
                return NaN;
            var first = this.rows[0];
            var last = this.rows[this.rows.length - 1];
            var delta = last.value - first.value;
            if (delta < 0)
                return NaN;
            var seconds = (last.timestamp.valueOf() - first.timestamp.valueOf()) / 1000;
            if (seconds <= 0)
                return NaN;
            return delta / seconds;
        }
    },
    methods: {
        loadData: function () {
            var _this = this;
            if (!this.channel)
                return;
            this.state.loading = true;
            axios.get("/api/data/".concat(this.channel.id, "/realtime/samples")).then(function (res) {
                var rows = res.data.map(function (row) { return (__assign(__assign({}, row), { timestamp: new Date(row.timestamp) })); });
                _this.rows = rows.sort(function (a, b) { return a.timestamp.valueOf() - b.timestamp.valueOf(); });
                _this.state.ready = true;
                _this.state.loading = false;
            });
        }
    }
});
