<template>
    <grid :loading="this.state.loading">
        <template v-slot:title>
            Statistiche tempi di lettura
        </template>
        <div v-if="state.ready">
            <columns-chart :chartData="chartData" :options="chartOptions" style="height:165px" ></columns-chart>
            <p class="text-muted fs-80 mb-0 mt-3"><i class="fa fa-info-circle"></i> I tempi di lettura sono riferiti agli ultimi 60 minuti di lavoro</p>
        </div>
        <loading-placeholder v-else />
        <template v-slot:buttons>
            <b-dropdown-item @click="reset()">
                Resetta le statistiche
            </b-dropdown-item>
        </template>
    </grid>
</template>
<script>
const axios = require('axios').default;
import ColumnsChart from '@/components/charts/ColumnsChart.vue';

export default {
    components:{ ColumnsChart },
    data(){
        return {
            stats: {},
            state:{
                loading: false,
                ready: false
            },
            chartOptions:{
                responsive: true, 
                maintainAspectRatio: false,
                legend:{
                    display:false
                },
                scales:{
                    y: {
                        title: 'Secondi [s]'
                    },
                    x:{
                        display:false
                    }
                }
            }

        }
    },
    mounted(){
        this.refresh();
    },
    computed:{
        chartData(){

            let values = Object.keys(this.stats).map(id => {
                const time = this.stats[id].avgChannelTime;
                const label = "["+this.stats[id].device.id+"]";
                return { label: label, value: time};
            });

            return {
                labels:values.map(v => v.label),
                datasets:[{
                    label: 'Tempo medio di lettura canale',
                    data: values.map(v => v.value),
                    backgroundColor: '#777',
                }]
            }
        }
    },
    methods:{
        refresh(){
            this.state.loading = true;
            axios.get("/api/data/stats").then(result => {
                this.stats = result.data;
                this.state.loading = false;
                this.state.ready = true;
            }).catch(err => {
                this.state.loading = false;
            });
        },
        reset(){
            axios.post("/api/data/stats/reset").then(result => {
                if (result.status == 200) {
                    this.showToast('Statistiche resettate', 'Le statistiche sono state resettate', 'success');
                    this.refresh();
                }
            })
        }
    }
}
</script>