<template>
    <grid>
        <template v-slot:title>
            Applicazione
        </template>

        <div class="row align-items-center no-gutters mx-2">
            <div class="col-auto text-center pr-3">
                <i class="fa fa-cog icon"></i>
            </div>
            <div class="col">
                <p><span class="bold">Commit</span>: {{commit}}</p>
                <p><span class="bold">Status</span>: {{status}}</p>
            </div>
        </div>
    </grid>    
</template>
<script>
const axios = require('axios').default;
export default {
    data(){
        return {
            commit:'',
            status:'',
            downloadProgress:100
        }
    },
    mounted(){
        this.fetch();
    },
    methods:{
        fetch(){
            axios.get('/api/host/application/state').then(res => {
                Object.assign(this, res.data);
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.icon {
    font-size:2.5rem;
    color:#999;
}
h5 {
    margin-bottom:.15rem;
}
p {
    margin-bottom:.15rem;
    font-size:.9rem;
}
</style>