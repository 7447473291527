<template>
    <div>
        <page-title><i class="fa fa-cogs"></i> Impostazioni</page-title>
        <grid>
            <template v-slot:title>
                <i class="fa fa-network-wired"></i> Impostazioni di rete
            </template>

            <primary-connection-selector :connections="connections"></primary-connection-selector>
            <hr/>
            <b-tabs>
                    <b-tab v-for="conn of connections" :key="conn.name">
                        <template #title>
                            <network-icon :type="conn.type"></network-icon> {{conn.name}}
                        </template>
                        <connection-editor :name="conn.name" class="p-2"></connection-editor>
                    </b-tab>
                </b-tabs>
        </grid>
        <grid class="mt-3">
            <template v-slot:title>
                <i class="fa fa-globe-americas"></i> Dynamic DNS
            </template>
             <ddns-settings></ddns-settings>
        </grid>
    </div>
</template>
<script>
const axios = require('axios').default;
import ConnectionEditor from '@/components/settings/ConnectionEditor.vue';
import DDNSSettings from '@/components/settings/DDNSSettings.vue';
import PrimaryConnectionSelector from '@/components/settings/PrimaryConnectionSelector.vue';
import Vue from 'vue';
export default Vue.extend({
    components:{
        ConnectionEditor,
        'ddns-settings':DDNSSettings,
        PrimaryConnectionSelector
    },
    data(){
        return {
            connections:[],
            primaryConnection: ''
        }
    },
    mounted(){
        axios.get('/api/network/connections').then(conns => this.connections = conns.data);
        axios.get('/api/network/primary').then(result => this.primaryConnection = result.data.name);
    }

})
</script>