<template>
    <b-alert 
        show fade dismissible
        :variant="results != null && results.isOk ? 'success' : 'danger'"
        class="mt-3 mb-0"
    >
        <h4 class="alert-heading mt-1 mb-0">
            <span v-if="isStatusOk">Successo</span>
            <span v-else>Errore</span>
        </h4>
        <small>Data di esecuzione del test: {{ timestampStr }} ({{ execTime }}ms)</small>
        <hr class="mt-2" />
        <div class="alert-body">
            <p>
                Stato: <strong>{{ statusStr }} <span v-if="isProtocolSpecificError">({{ results.specificErrorCode }})</span></strong>
            </p>
            <p v-if="results.pluginName != null">
                Plugin: <strong>{{ results.pluginName }} (v{{ results.pluginVersion }})</strong>
            </p>
            <p>
                Valore letto: <strong>{{ nullableValue }}</strong>
            </p>
            
            <div v-if="results.info && results.info.length > 0">
                <p>Informazioni</p>
                <ul class="mb-1">
                    <li v-for="(entry, idx) in results.info" v-bind:key="idx">{{ entry }}</li>
                </ul>
            </div>
            <div v-if="results.errors && results.errors.length > 0">
                <p>Errori</p>
                <ul class="mb-1">
                    <li v-for="(entry, idx) in results.errors" v-bind:key="idx">{{ entry }}</li>
                </ul>
            </div>
        </div>
    </b-alert>
</template>
<script>
import moment from "moment";
import { EciStatusCode } from "@/models/Data.js"

export default ({
    props: {
        results: { type: Object },
        timestamp: { type: Date }
    },
    data() {
        return {

        }
    },
    computed: {
        nullableValue () {
            if (this.results.value == null)
                return "-";
            return this.results.value;
        },
        isStatusOk () {
            return this.results.status == EciStatusCode.Ok;
        },
        isProtocolSpecificError () {
            return this.results.status == EciStatusCode.ProtocolError;
        },
        statusStr() {
            return EciStatusCode.ToString(this.results.status);
        },
        timestampStr() {
            return moment(this.timestamp).format("YYYY-MM-DD HH:mm:ss.SSS");
        },
        execTime() {
            let start = moment(this.timestamp);
            let duration = moment.duration(moment().diff(start))
            return duration.asMilliseconds();
        }
    }
})
</script>
<style lang="scss" scoped>
    .alert-body p {
        margin-bottom: .4rem;
    }
</style>